import React from "react";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import { NavPillsRU } from "./CardRU-Sections";
// import Data from "services/Data/Data";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";
import NavPills from "components/NavPills/NavPills";


export default function CardRU(props) {

	var user = useSelector(getStoreUser);

	var tabs_devices = user.devices
		.filter(device => device.type === "vehicle")    /* filter available people */
		.map(device => {
			return {
				tabButton: device.label,
				tabContent: (
					<NavPillsRU
						reportsUsura={props.reportsUsura}
					></NavPillsRU>
				)
			};
		});

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="blorin">
						<h3>
							Report usura della batteria dei tuoi veicoli
						</h3>
					</CardHeader>
					<CardBody>
						<NavPills
							color="blorin"
							tabs={tabs_devices}
						/>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
