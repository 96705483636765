import React from "react";
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
// import { Button, Collapse } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Charts from "components/Blorin/Charts/Charts";
import GridContainer from "components/Grid/GridContainer";
// import Icon from "@material-ui/core/Icon";

import CardIcon from "components/Card/CardIcon.js";
import DateRange from "@material-ui/icons/DateRange";
import Euro from "@material-ui/icons/Euro";
import Circle from "@material-ui/icons/FiberManualRecord";
import CardFooter from "components/Card/CardFooter.js";

// import { CardStatoCharts, CardStatoIndicatori } from "./CardST-Sections";

const useStyles = makeStyles(styles);

export default function CardRete(props) {
	const classes = useStyles();

	return (
		<>
			<Charts
				type="vehicle"
				idChart="RetePrel"
				device={props.device}
				title={props.title}
			/>
			{/* style={{ textAlign: 'center' }} */}
			<div className={classes.legendaHighchartsDiv}>
				<Circle className={classes.circleLegendHighchartsG}></Circle> <span className={classes.legendaHighchartsText}>Potenza di carica della batteria</span> <br></br>
				<Circle className={classes.circleLegendHighchartsP}></Circle> <span className={classes.legendaHighchartsText}> Potenza immessa in rete dalla batteria</span>
			</div>
			<hr className="col-xs-12"></hr>
			<GridContainer>
				<GridItem xs={12} sm={12} md={5}>
					<Card>
						<CardHeader color="success" stats icon >
							<CardIcon color="success">
								<Euro />
							</CardIcon>
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									<p className={classes.cardCategory}>
										Guadagno corrispondente
									</p>
									<h3 className={classes.cardTitle}>
										€34,24
									</h3>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<p className={classes.cardCategory}>
										Token
									</p>
									<h3 className={classes.cardTitle}>
										75
									</h3>
								</GridItem>
							</GridContainer>
						</CardBody>
						<CardFooter stats>
							<div className={classes.stats}>
								<DateRange />
								Dal 01/01/2021
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				{/* <GridItem xs={12} sm={12} md={4}>
					<Card>
						<CardHeader color="success" stats icon>
							<CardIcon color="success">
								<Icon>paid</Icon>
							</CardIcon>
							<p className={classes.cardCategory}>
								Token
							</p>
							<h3 className={classes.cardTitle}>
								75
							</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<DateRange />
								Dal 01/01/2021
							</div>
						</CardFooter>
					</Card>
				</GridItem> */}
			</GridContainer>
		</>
	);
}

export function CardReteDSO(props) {
	const classes = useStyles();

	return (
		<>
			<Charts
				type="vehicle"
				idChart="RetePrel"
				device={props.device}
				title={props.title}
			/>
			{/* style={{ textAlign: 'center' }} */}
			<div className={classes.legendaHighchartsDiv}>
				<Circle className={classes.circleLegendHighchartsG}></Circle> <span className={classes.legendaHighchartsText}>Potenza di carica della batteria</span> <br></br>
				<Circle className={classes.circleLegendHighchartsP}></Circle> <span className={classes.legendaHighchartsText}> Potenza immessa in rete dalla batteria</span>
			</div>
		</>
	);
}
