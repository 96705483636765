import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
import AuthService from "services/Auth/auth.service";
// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { useHistory } from "react-router";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";
// ...

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
	// const [openNotification, setOpenNotification] = React.useState(null);
	// const handleClickNotification = (event) => {
	// 	if (openNotification && openNotification.contains(event.target)) {
	// 		setOpenNotification(null);
	// 	} else {
	// 		setOpenNotification(event.currentTarget);
	// 	}
	// };
	// const handleCloseNotification = () => {
	// 	setOpenNotification(null);
	// };
	const history = useHistory();
	const [openProfile, setOpenProfile] = React.useState(null);
	const handleClickProfile = (event) => {
		if (openProfile && openProfile.contains(event.target)) {
			setOpenProfile(null);
		} else {
			setOpenProfile(event.currentTarget);
		}
	};
	const handleCloseProfile = (e) => {
		setOpenProfile(null);
		switch (e.target.id) {
			case "logout":
				AuthService.logout(props);
				history.push("/auth/login");
				break;
			default:
				break;
		}
	};
	const classes = useStyles();
	const dropdownItem = classNames(classes.dropdownItem);

	const managerClasses = classNames({
		[classes.managerClasses]: true,
	});
	var user = useSelector(getStoreUser);
	var user_Name = user.name;
	var role = (user.roles.includes("ROLE_DSO") || user.roles.includes("ROLE_DSO_V2G") ? "DSO" : "Prosumer");

	return (
		<div>
			<div className={managerClasses}>
				<b>{user_Name}</b> - <span>{role}</span>
			</div>
			<div className={managerClasses}>
				<Button
					color="transparent"
					aria-label="Person"
					justIcon
					aria-owns={openProfile ? "profile-menu-list" : null}
					aria-haspopup="true"
					onClick={handleClickProfile}
					className={classes.buttonLink}
					muiClasses={{
						label: "",
					}}
				>
					<Person className={classes.headerLinksSvg} />
					<Hidden mdUp implementation="css">
						<span
							onClick={handleClickProfile}
							className={classes.linkText}
						>
							{"Account"}
						</span>
					</Hidden>
				</Button>
				<Popper
					open={Boolean(openProfile)}
					anchorEl={openProfile}
					transition
					disablePortal
					placement="bottom"
					className={classNames({
						[classes.popperClose]: !openProfile,
						[classes.popperResponsive]: true,
						[classes.popperNav]: true,
					})}
				>
					{({ TransitionProps }) => (
						<Grow
							{...TransitionProps}
							id="profile-menu-list"
							style={{ transformOrigin: "0 0 0" }}
						>
							<Paper className={classes.dropdown}>
								<ClickAwayListener
									onClickAway={handleCloseProfile}
								>
									<MenuList role="menu">
										<MenuItem
											onClick={handleCloseProfile}
											className={dropdownItem}
										>
											{"Account"}
										</MenuItem>
										<MenuItem
											onClick={handleCloseProfile}
											className={dropdownItem}
										>
											{"Settings"}
										</MenuItem>
										<Divider light />
										<MenuItem
											onClick={handleCloseProfile}
											id="logout"
											className={dropdownItem}
										>
											{"Log out"}
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		</div>
	);
}

HeaderLinks.propTypes = {
	rtlActive: PropTypes.bool,
};
