import axios from "axios";
import authHeader from "services/Auth/auth-header";

// const API_URL = "http://80.211.145.77:8500/api/";
const API_URL = "https://smet.exaltoenergia.it:8500/api/";

export default class Data {
	/** Funzione generale per Live */

	static getDataLive = (props) => {
		var json = props;
		return axios.post(API_URL + "data/live", json, {
			headers: authHeader()
		});
	};

	/** Funzione generale per History */
	static getDataHistory = (props) => {
		var json = props;
		if (json.type === "cabin") {
			return axios.post(API_URL + "data/historyCabin", json, {
				headers: authHeader(),
			});
		} else if (json.type === "building") {
			return axios.post(API_URL + "data/historyDr", json, {
				headers: authHeader(),
			});
		} else {

			return axios.post(API_URL + "data/history", json, {
				headers: authHeader(),
			});
		}
	};

	static getData = () => {
		return axios.get(API_URL + "data", { headers: authHeader() });
	};

	static getDataChart = (chartId, serieIds, type) => {
		var json = JSON.stringify({ chartId, serieIds, type });
		return axios.post(API_URL + "data/chart", json, {
			headers: authHeader(),
		});
	};

	/** Official */
	static getV2GVehicle = () => {
		const data = [
			{
				marca: "Nissan",
				modello: "e-NV200",
				targa: "EF434GF",
				desc: "VSKHAAME0U0616423",
				caratteristiche: [
					"Potenza massima 80 kW (109 CV)",
					"Capacità batteria: 40 kWh",
					"Autonomia ciclo combinato: 200 km",
					"Autonomia ciclo urbano: 301 km",
				],
			},
		];

		return data[0];
	};

	static getReportsUsura = () => {
		const reportsUsura = [
			{
				type: "week",
				label: "Settimana corrente",
				reports: [
					{
						status: "info",
						message: "Numero di cicli di carica/scarica: 6",
						size: "6",
					},
					{
						status: "success",
						message: "Numero di ricariche rapide: 0",
						size: "6",
					},
					{
						status: "danger",
						message: "Durata in cui la batteria è stata carica per meno del 20%: 1 ore",
						size: "6",
					},
					{
						status: "success",
						message: "Durata in cui la batteria è stata carica al 100% : 15 ore",
						size: "6",
					},
					{
						status: "info",
						message: "Riduzione dello stato di salute della batteria (State of Health, SoH): 0,05 %",
						size: "6",
					},
					{
						status: "danger",
						message: "Periodo max inattività auto: 10 ore",
						size: "6",
					},
					{
						status: "info",
						message:
							"Tempo auto parcheggiata ad una temperatura superiore ai 25 °C: 1 ore",
					},
					{
						status: "danger",
						message:
							"Tempo auto in carica ad una temperatura superiore ai 25 °C: 1 ore",
					},
				],
			},
			{
				type: "month",
				label: "Mese corrente",
				reports: [
					{
						status: "info",
						message: "Numero di cicli di carica/scarica: 16",
						size: "6",
					},
					{
						status: "danger",
						message: "Numero di ricariche rapide: 4",
						size: "6",
					},
					{
						status: "danger",
						message: "Durata in cui la batteria è stata carica per meno del 20% : 10 ore",
						size: "6",
					},
					{
						status: "success",
						message: "Durata in cui la batteria è stata carica al 100% : 0 ore",
						size: "6",
					},
					{
						status: "info",
						message: "Riduzione dello stato di salute della batteria (State of Health, SoH): 3,00 %",
						size: "6",
					},
					{
						status: "danger",
						message: "Periodo max inattività auto: 10 ore",
						size: "6",
					},
					{
						status: "danger",
						message:
							"Tempo auto parcheggiata ad una temperatura superiore ai 25 °C: 25 ore",
					},
					{
						status: "danger",
						message:
							"Tempo auto in carica ad una temperatura superiore ai 25 °C: 16 ore",
					},
				],
			},
			{
				type: "overall",
				label: "Intero periodo",
				reports: [
					{
						status: "info",
						message: "Numero di cicli di carica/scarica: 175",
						size: "6",
					},
					{
						status: "danger",
						message: "Numero di ricariche rapide: 49",
						size: "6",
					},
					{
						status: "danger",
						message: "Durata in cui la batteria è stata carica per meno del 20%: 156 ore",
						size: "6",
					},
					{
						status: "danger",
						message: "Durata in cui la batteria è stata carica al 100% : 435 ore",
						size: "6",
					},
					{
						status: "danger",
						message: "Riduzione dello stato di salute della batteria (State of Health, SoH): 10,05 %",
						size: "6",
					},
					{
						status: "danger",
						message: "Periodo max inattività auto: 1203 ore",
						size: "6",
					},
					{
						status: "danger",
						message:
							"Tempo auto parcheggiata ad una temperatura superiore ai 25 °C: 790 ore",
					},
					{
						status: "danger",
						message:
							"Tempo auto in carica ad una temperatura superiore ai 25 °C: 432 ore",
					},
				],
			},
		];

		return reportsUsura;
	};

	static getEvents = (props) => {
		var json = props;
		console.log((new Date()).toISOString() + " Get Events");
		return axios.post(API_URL + "data/events", json, {
			headers: authHeader()
		});
	};

	static addEventsV2G = (props) => {
		var json = props;
		return axios.post(API_URL + "data/addeventv2g", json, {
			headers: authHeader()
		});
	};

	static addEventsDR = (props) => {
		var json = props;
		return axios.post(API_URL + "data/addeventdr", json, {
			headers: authHeader()
		});
	};

	static addPolicy = (props) => {
		var json = props;
		return axios.post(API_URL + "data/addpolicy", json, {
			headers: authHeader()
		});
	};

	static testAuth = (props) => {
		var json = props;
		return axios.post(API_URL + "testnew", json, {
			headers: authHeader()
		});
	};

	static getLogFile = () => {
		var json = {};

		// fetch("url",
		// { 
		//     method: "POST",
		//     headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.localStorage["Access_Token"]},
		//     body:data
		// }).then(response => response.blob()).then(response => ...*your code for download*... )

		return axios.post(API_URL + "logDownload", json, {
			headers: authHeader()
		});
	};



}
