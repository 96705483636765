
import Data from "services/Data/Data";
import { setStoreEventDR, setStoreEventV2G } from 'services/Redux/StoreSlice';
import { store } from 'services/Redux/Store';


export function updateEvents() {
	Data.getEvents({ type: "dr" }).then((res) => {
		var jsonDataLive = res.data.response;
		store.dispatch(setStoreEventDR(jsonDataLive));
		// console.log("After dispatch events", jsonDataLive);
	});

	Data.getEvents({ type: "v2g" }).then((res) => {
		var jsonDataLive = res.data.response;
		store.dispatch(setStoreEventV2G(jsonDataLive));
		// console.log("After dispatch events", jsonDataLive);
	});

}