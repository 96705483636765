import {
	successColor,
	tooltip,
	cardTitle,
	grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const dashboardStyle = {
	...hoverCardStyle,
	tooltip,
	cardTitle: {
		...cardTitle,
		marginTop: "0px",
		marginBottom: "3px",
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px",
	},
	cardProductTitle: {
		...cardTitle,
		marginTop: "0px",
		marginBottom: "3px",
		textAlign: "center",
	},
	cardProductTitleImageContainer: {
		width: "100%",
		marginTop: "10px",
		marginBottom: "3px",
		textAlign: "center",
	},
	cardProductTitleImage: {
		width: "100%",
		marginTop: "10px",
		marginBottom: "3px",
		textAlign: "center",
	},
	cardCategory: {
		color: grayColor[0],
		fontSize: "14px",
		paddingTop: "10px",
		marginBottom: "0",
		marginTop: "0",
		margin: "0",
	},
	cardProductDesciprion: {
		textAlign: "center",
		color: grayColor[0],
	},
	cardReportTitle: {
		textAlign: "center",
		margin: "10px",
		fontSize: "2em",
		width: "100%",
	},
	stats: {
		color: grayColor[0],
		fontSize: "12px",
		lineHeight: "22px",
		display: "inline-flex",
		"& svg": {
			position: "relative",
			top: "4px",
			width: "16px",
			height: "16px",
			marginRight: "3px",
		},
		"& .fab,& .fas,& .far,& .fal,& .material-icons": {
			position: "relative",
			top: "4px",
			fontSize: "16px",
			marginRight: "3px",
		},
	},
	productStats: {
		paddingTop: "7px",
		paddingBottom: "7px",
		margin: "0",
	},
	successText: {
		color: successColor[0],
	},
	upArrowCardCategory: {
		width: 14,
		height: 14,
	},
	underChartIcons: {
		width: "17px",
		height: "17px",
	},
	price: {
		color: "inherit",
		"& h4": {
			marginBottom: "0px",
			marginTop: "0px",
		},
	},

	legendaHighchartsDiv: {
		textAlign: "center",
	},

	legendaHighchartsText: {
		verticalAlign: "top",
		fontSize: "12px",
		fontWeight: "bold",
		fontFamily: ["Lucida Grande", "Lucida Sans Unicode", "Arial", "Helvetica", "sans-serif"]
	},

	circleLegendHighchartsG: {
		verticalAlign: "top",
		color: '#66ff66',
		fontSize: "18px",
	},
	circleLegendHighchartsP: {
		verticalAlign: "top",
		color: '#ffad99',
		fontSize: "18px",
	}
};

export default dashboardStyle;
