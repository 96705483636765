// import { Gauge } from "services/HighCharts/Gauge";
// import * as ChartData from "services/HighCharts/HighChartsData1";

import {
	dataGroupingSets,
	rangeSelector,
} from "services/HighCharts/ChartStructureOptions";

export default function ChartOptions(idChart, props = null) {
	let options = {};
	let data;
	switch (idChart) {
		case "StatoTemperature":
		case "StatoSoc":
		case "SOC":
		case "RetePrel":
		case "ECPRodCons":
		case "DrPotenza":
		case "DrPotenzaPV":
		case "DrPotenzaOnly":
		case "PowerPV":
		case "DrEMS1":
		case "DrEMS2":
		case "DrEMS3":
		case "DrEMS4":
		case "DrRemunerazione":
			data = getChartOptionsData(idChart, props);
			options = ChartStockLines(idChart, data);
			break;
		case "ReteCB":
			data = getChartOptionsData(idChart, props);
			options = ChartStockLines2(idChart, data);
			break;

		// case "V2GRetePrel":
		// 	data = ChartData.V2GRetePrel_Data;
		// 	options = ChartStockLines(idChart, data);
		// 	chart = Stock(options);
		// 	break;

		// case "StatoSoc":
		// 	data = ChartData.StatoSoc_Data;
		// 	options = ChartStockLines(idChart, data);
		// 	chart = Stock(options);
		// 	break;
		// case "DrPotenza":
		// 	data = ChartData.DrPotenza_Data;
		// 	options = ChartStockLines(idChart, data);
		// 	chart = Stock(options);
		// 	break;
		// case "DrEnergia":
		// 	data = ChartData.DrEnergia_Data;
		// 	options = ChartStockLines(idChart, data);
		// 	chart = Stock(options);
		// 	break;
		// case "DrRemunerazione":
		// 	data = ChartData.DrRemunerazione_Data;
		// 	options = ChartStockLines(idChart, data);
		// 	chart = Stock(options);
		// 	break;
		case "V2GReteSOC":
		case "V2GReteSOH":
		case "V2GReteGTemp":
			data = getChartOptionsData(idChart, props);
			options = ChartGauge(idChart, data);
			// chart = Gauge(options);
			break;
		default:
			break;
	}
	// let mergedOptions = Object.assign(globalOptions, options);
	// options.lang = globalOptions.lang
	return options;
}

/** Funzione per la generazione dei grafici a linee */
function ChartStockLines2(idChart, data) {
	var chart = {
		boost: {
			useGPUTranslations: true,
			// Chart-level boost when there are more than 5 series in the chart
			seriesThreshold: 5,
		},
		chart: {
			type: data.tipo,
			zoomType: "x",
			id: idChart,
		},
		title: {
			text: data.title,
		},
		subtitle: {
			text: data.subtitle,
		},
		yAxis: [
			{
				// softMin: 0,
				title: {
					text:
						data.yAxis +
						(typeof data.yUnit !== "undefined" && data.yUnit !== ""
							? " (" + data.yUnit + ")"
							: ""),
					//useHTML: true, RIMOSSO PERCHE' DA' PROBLEMI CON I TOOLTIP
				},
				// offset: 20,
			},
			{
				// softMin: 0,
				title: {
					text:
						data.yAxis2 +
						(typeof data.yUnit2 !== "undefined" &&
							data.yUnit2 !== ""
							? " (" + data.yUnit2 + ")"
							: ""),
					//useHTML: true, RIMOSSO PERCHE' DA' PROBLEMI CON I TOOLTIP
				},
				// offset: 20,
				opposite: false,
			},
		],
		xAxis: {
			type: "datetime",
			ordinal: false, // Necessario per evitare di avere "buchi temporali" in caso di assenza di alcuni valori
			plotBands: data.plotBands,
		},
		credits: {
			enabled: false,
		},
		legend: (typeof data.legend !== "undefined" ? data.legend : { enabled: true }),

		plotOptions: {
			series: {
				gapSize: 0, //Opzione per risolere il problema dei buchi. Valori più piccoli fanno vedere più buchi. Quando c'è lo zoom massimo (dataGrouping = 1 minuto) è espresso in minuti, vedi gas di Valentino
				stacking: data.stacking,
				dataGrouping: (function () {
					var this_dataGrouping = dataGroupingSets[data.dataGrouping];
					if (data.type === "column") {
						if (data.series.length > 1) {
							this_dataGrouping.groupPixelWidth = 250;
						} else {
							this_dataGrouping.groupPixelWidth = 100;
						}
					}
					return this_dataGrouping;
				})(),
			},
			column: {
				maxPointWidth: 50, // Serve a evitare colonne troppo larghe se sono poche
				//getExtremesFromAll:true,
			},
		},
		navigator: {
			height: 40,
			xAxis: {
				gridLineWidth: 1,
			},
			series: {
				type: data.type,
				dataGrouping: (function () {
					var this_dataGrouping = dataGroupingSets[data.dataGrouping];
					if (data.type === "column") {
						this_dataGrouping = dataGroupingSets[3];
						//this_dataGrouping.groupPixelWidth = (data.groupPixelWidth > 0? data.groupPixelWidth : 2);
					}
					return this_dataGrouping;
				})(),
			},
		},
		// tooltip: [
		// 	{
		// 		valueSuffix: " " + data.yUnit,
		// 		valueDecimals: data.precision,
		// 	},
		// 	{
		// 		valueSuffix: " " + data.yUnit2,
		// 		valueDecimals: data.precision,
		// 	},
		// ],

		rangeSelector: {
			buttons: rangeSelector[data.dataRangeSelector.range],
			// selected: data.dataRangeSelector.selected,
		},
		series: data.series,
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 500,
					},
					chartOptions: {
						legend: {
							layout: "horizontal",
							align: "center",
							verticalAlign: "bottom",
						},
					},
				},
			],
		},
	};
	return chart;
}

export function ChartStockLines(idChart, data) {
	var chart = {
		chart: {
			type: data.type,
			zoomType: "x",
			id: idChart,
			// animation: false,
			// events: {
			// 	load: function () {
			// 		var series = this.series[0];
			// 		var x = new Date().getTime(), // current time
			// 			y = Math.round(Math.random() * 100);
			// 		setInterval(function () {
			// 			console.log("Y : ", y);
			// 			series.addPoint([x, y], true, true);
			// 		}, 5000);
			// 	},
			// },
		},
		title: {
			text: data.title,
		},
		subtitle: {
			text: data.subtitle,
		},
		yAxis: {
			softMin: 0,
			title: {
				text:
					data.yAxis +
					(typeof data.yUnit !== "undefined" && data.yUnit !== ""
						? " (" + data.yUnit + ")"
						: ""),
				//useHTML: true, RIMOSSO PERCHE' DA' PROBLEMI CON I TOOLTIP
			},
			offset: 20,
		},
		xAxis: {
			type: "datetime",
			ordinal: false, // Necessario per evitare di avere "buchi temporali" in caso di assenza di alcuni valori
			plotBands: data.plotBands,
		},
		credits: {
			enabled: false,
		},
		legend: (typeof data.legend !== "undefined" ? data.legend : { enabled: true }),
		plotOptions: {
			series: {
				gapSize: 0, //Opzione per risolere il problema dei buchi. Valori più piccoli fanno vedere più buchi. Quando c'è lo zoom massimo (dataGrouping = 1 minuto) è espresso in minuti, vedi gas di Valentino
				stacking: data.stacking,
				dataGrouping: (function () {
					var this_dataGrouping = dataGroupingSets[data.dataGrouping];
					if (data.type === "column") {
						if (data.series.length > 1) {
							this_dataGrouping.groupPixelWidth = 250;
						} else {
							this_dataGrouping.groupPixelWidth = 100;
						}
					}
					return this_dataGrouping;
				})(),
			},
			column: {
				maxPointWidth: 50, // Serve a evitare colonne troppo larghe se sono poche
				//getExtremesFromAll:true,
			},
		},
		navigator: {
			height: 40,
			xAxis: {
				gridLineWidth: 1,
			},
			series: {
				type: data.type,
				dataGrouping: (function () {
					var this_dataGrouping = dataGroupingSets[data.dataGrouping];
					if (data.type === "column") {
						this_dataGrouping = dataGroupingSets[3];
						//this_dataGrouping.groupPixelWidth = (data.groupPixelWidth > 0? data.groupPixelWidth : 2);
					}
					return this_dataGrouping;
				})(),
			},
		},
		tooltip: {
			valueSuffix: " " + data.yUnit,
			valueDecimals: data.precision,
		},
		rangeSelector: {
			buttons: rangeSelector[data.dataRangeSelector.range],
			// 	selected: data.dataRangeSelector.selected,
		},
		series: data.series,
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 500,
					},
					chartOptions: {
						legend: {
							layout: "horizontal",
							align: "center",
							verticalAlign: "bottom",
						},
					},
				},
			],
		},
	};
	return chart;
}

function ChartGauge(idChart, data) {
	var chart = {
		credits: {
			enabled: false,
		},
		chart: {
			type: "gauge",
			plotBackgroundColor: null,
			plotBackgroundImage: null,
			plotBorderWidth: 0,
			plotShadow: false,
			id: idChart,
		},

		title: {
			text: data.title,
			style: {
				fontSize: "24px",
			},
		},
		subtitle: {
			text: data.subtitle,
			style: {
				fontSize: "15px",
			},
		},
		tooltip: {
			enabled: true,
			shadow: false,
			valueSuffix: data.unit,
		},
		pane: {
			startAngle: -90,
			endAngle: 90,
			background: null,
		},
		plotOptions: {
			gauge: {
				dataLabels: {
					enabled: false,
				},
				dial: {
					baseLength: "0%",
					baseWidth: 10,
					radius: "100%",
					rearLength: "0%",
					topWidth: 1,
				},
			},
		},
		// the value axis
		yAxis: {
			labels: {
				enabled: true,
				distance: 10,
			},
			tickPositions: data.tickPositions,
			minorTickLength: data.minorTickLength,
			min: data.min,
			max: data.max,
			plotBands: data.plotBands,
		},
		series: data.series,
	};
	return chart;
}

function getChartOptionsData(idChart, props) {
	var optionsData;
	switch (idChart) {
		case "StatoTemperature":
			optionsData = {
				title: "Temperature",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Temperatura",
				yUnit: "°C",
				desc: "Temperatura",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 6,
				},
				seriesId: ["temp_ambiente", "temp_motore", "temp_batteria"],
				series: [
					{
						name: "Ambiente",
						id: "temp_ambiente",
						color: "green",
						type: "line",
					},
					{
						name: "Motore",
						id: "temp_motore",
						color: "blue",
						type: "line",
					},
					{
						name: "Batterie",
						id: "temp_batteria",
						color: "red",
						negativeColor: "#ffad99",
						type: "line",
					},
				],
			};
			break;
		case "StatoSoc":
			optionsData = {
				title: "Stato di carica della batteria",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "",
				yUnit: "%",
				desc: "",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				series: [
					{
						name: "SoC",
						id: "soc",
						color: "green",
						type: "line",
					},
				],
			};
			break;
		case "SOC":
			optionsData = {
				title: "SoC",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "",
				yUnit: "%",
				desc: "",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				series: [
					{
						name: "SoC",
						id: "soc",
						color: "green",
						type: "line",
					},
				],
			};
			break;
		case "RetePrel":
			optionsData = {
				title: "Prelievo energia dalla rete/Immissione energia in rete",
				type: "area",
				stock: "stock",
				stacking: "normal",
				subtitle: "",
				yAxis: "Potenza",
				yUnit: "kW",
				desc: "Potenza",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				legend: {
					enabled: false
				},
				series: [
					{
						name: "Potenza",
						id: "potenza",
						color: "#66ff66",
						negativeColor: "#ffad99",
						type: "area",
					},
				],
				plotBands: [
					{
						from: new Date("2021-04-07 07:00:00").getTime(),
						to: new Date("2021-04-07 09:00:00").getTime(),
						color: "#ffeb99",
						borderColor: "black",
						label: { text: "Evento V1G" },
					},
					{
						from: new Date("2021-04-07 15:00:00").getTime(),
						to: new Date("2021-04-07 17:00:00").getTime(),
						color: "#ffeb99",
						borderColor: "black",
						label: { text: "Evento V2G" },
					},
				],
			};
			break;
		case "ReteCB":
			optionsData = {
				title: "Temperatura e stato di carica della batteria del veicolo Nissan e-NV200",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "SOC",
				yAxis2: "Temepratura",
				yUnit: "%",
				yUnit2: "°C",
				desc: "Potenza",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				seriesId: ["soc", "temp_batteria"],
				series: [
					{
						name: "Stato di carica della batteria del veicolo",
						id: "soc",
						color: "red",
						negativeColor: "#ffad99",
						type: "line",
						yAxis: 0,
					},
					{
						name: "Temperatura batterie",
						id: "temp_batteria",
						color: "blue",
						negativeColor: "#ffad99",
						type: "line",
						yAxis: 1,
					},
				],
			};
			break;
		case "ECPRodCons":
			optionsData = {
				title: "Energia",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Energia",
				yUnit: "kWh",
				desc: "Energia",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				series: [
					// {
					// 	name: "Prodotta",
					// 	id: "L1_W",
					// 	color: "green",
					// 	type: "line",
					// },
					{
						name: "Consumata",
						id: "L1_W",
						color: "blue",
						type: "line",
					},
				],
			};
			break;
		case "DrPotenza":
			optionsData = {
				title: "Potenza",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Potenza",
				yUnit: "kW",
				desc: "Potenza",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				// plotBands: [
				// 	{
				// 		from: new Date("2022-08-05 07:00:00").getTime(),
				// 		to: new Date("2022-08-05 09:00:00").getTime(),
				// 		color: "#ffeb99",
				// 		borderColor: "black",
				// 		label: { text: "Evento V1G" },
				// 	},
				// 	{
				// 		from: new Date("2022-08-05 15:00:00").getTime(),
				// 		to: new Date("2022-08-05 17:00:00").getTime(),
				// 		color: "#ffeb99",
				// 		borderColor: "black",
				// 		label: { text: "Evento V2G" },
				// 	},
				// ],
				series: [
					{
						name: "Potenza",
						id: "L1_W",
						color: "blue",
						type: "line",
					},
					{
						name: "Baseline",
						id: "baseline",
						color: "orange",
						type: "line",
					},
					{
						name: "Target",
						id: "target",
						color: "red",
						type: "line",
						dataGrouping: {
							enabled: false,
							// units: [
							// 	'week', // unit name
							// 	[1] // allowed multiples
							// ]
						},
					},
				],
			};
			break;
		case "DrPotenzaPV":
			optionsData = {
				title: "Potenza",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Potenza",
				yUnit: "kW",
				desc: "Potenza",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				// plotBands: [
				// 	{
				// 		from: new Date("2022-08-05 07:00:00").getTime(),
				// 		to: new Date("2022-08-05 09:00:00").getTime(),
				// 		color: "#ffeb99",
				// 		borderColor: "black",
				// 		label: { text: "Evento V1G" },
				// 	},
				// 	{
				// 		from: new Date("2022-08-05 15:00:00").getTime(),
				// 		to: new Date("2022-08-05 17:00:00").getTime(),
				// 		color: "#ffeb99",
				// 		borderColor: "black",
				// 		label: { text: "Evento V2G" },
				// 	},
				// ],
				series: [
					{
						name: "Potenza PV",
						id: "PVPower",
						color: "green",
						type: "line",

					},
					{
						name: "Potenza",
						id: "L1_W",
						color: "blue",
						type: "line",
						visible: false,
					},
					{
						name: "Baseline",
						id: "baseline",
						color: "orange",
						type: "line",
						visible: false,
					},
					{
						name: "Target",
						id: "target",
						color: "red",
						type: "line",
						visible: false,
						dataGrouping: {
							enabled: false,
							// units: [
							// 	'week', // unit name
							// 	[1] // allowed multiples
							// ]
						},
					},
				],
			};
			break;
		case "DrPotenzaOnly":
			optionsData = {
				title: "Potenza",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Potenza",
				yUnit: "kW",
				desc: "Potenza",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				series: [
					{
						name: "Potenza",
						id: "L1_W",
						color: "blue",
						type: "line",
					},
				],
			};
			break;
		case "PowerPV":
			optionsData = {
				title: "Potenza",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Potenza",
				yUnit: "kW",
				desc: "Potenza",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				series: [
					{
						name: "Potenza",
						id: "L1_W",
						color: "blue",
						type: "line",
					},
					// {
					// 	name: "Batterie",
					// 	id: "baseline",
					// 	color: "green",
					// 	type: "line",
					// },
					// {
					// 	name: "Grid",
					// 	id: "target",
					// 	color: "orange",
					// 	type: "line",
					// },
					// {
					// 	name: "Carico",
					// 	id: "target",
					// 	color: "red",
					// 	type: "line",
					// },
				],
			};
			break;
		case "DrEMS1":
		case "DrEMS2":
		case "DrEMS3":
		case "DrEMS4":
			optionsData = {
				title: "Potenza",
				type: "line",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Potenza",
				yUnit: "kW",
				desc: "Potenza",
				dataGrouping: 1,
				precision: 1,
				dataRangeSelector: {
					range: 1,
					selected: 0,
				},
				series: [
					{
						name: "Potenza",
						id: "L1_W",
						color: "blue",
						type: "line",
					},
				],
			};
			break;
		case "DrEnergia":
			optionsData = {
				title: "Energia consumata",
				type: "column",
				stock: "stock",
				stacking: "",
				subtitle: "",
				yAxis: "Energia",
				yUnit: "kWh",
				desc: "Energia",
				dataGrouping: 2,
				precision: 1,
				dataRangeSelector: {
					range: 2,
					selected: 1,
				},
				series: [
					{
						name: "Energia",
						id: "L1_W",
						color: "green",
						type: "column",
					},
				],
			};
			break;
		case "DrRemunerazione":
		case "V2GReteSOC":
			optionsData = {
				title: "SoC",
				unit: "%",
				subtitle: "",
				series: [
					{
						name: "SOC",
						data: [80],
					},
				],
				tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
				minorTickLength: 0,
				min: 0,
				max: 100,
				plotBands: [
					{
						from: 0,
						to: 25,
						color: "rgb(192, 0, 0)", // red
						thickness: "50%",
					},
					{
						from: 25,
						to: 75,
						color: "rgb(255, 192, 0)", // yellow
						thickness: "50%",
					},
					{
						from: 75,
						to: 100,
						color: "rgb(155, 187, 89)", // green
						thickness: "50%",
					},
				],
			}
			break;
		case "V2GReteSOH":
			optionsData = {
				title: "SoH",
				unit: "%",
				subtitle: "",
				series: [
					{
						name: "SOH",
						data: [90],
					},
				],
				tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
				minorTickLength: 0,
				min: 0,
				max: 100,
				plotBands: [
					{
						from: 0,
						to: 25,
						color: "rgb(192, 0, 0)", // red
						thickness: "50%",
					},
					{
						from: 25,
						to: 75,
						color: "rgb(255, 192, 0)", // yellow
						thickness: "50%",
					},
					{
						from: 75,
						to: 100,
						color: "rgb(155, 187, 89)", // green
						thickness: "50%",
					},
				],
			};
			break;
		case "V2GReteGTemp":
			optionsData = {
				title: "Temperatura Ambiente",
				unit: "°C",
				subtitle: "",
				series: [
					{
						name: "Temperatura",
						data: [19],
					},
				],
				tickPositions: [-30, -20, -10, 0, 10, 20, 30, 40, 50, 60],
				minorTickLength: 0,
				min: -30,
				max: 60,
				plotBands: [
					{
						from: -30,
						to: 0,
						color: "rgb(192, 0, 0)", // red
						thickness: "50%",
					},
					{
						from: 0,
						to: 30,
						color: "rgb(155, 187, 89)", // green

						thickness: "50%",
					},
					{
						from: 30,
						to: 60,
						color: "rgb(192, 0, 0)", // red
						thickness: "50%",
					},
				],
			};
			break;

		default:
			break;
	}

	if (props != null) {
		if (props.title != undefined) {
			optionsData.title = props.title;
		}
	}

	return optionsData;
}