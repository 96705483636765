import {
	warningCardHeader,
	successCardHeader,
	dangerCardHeader,
	infoCardHeader,
	primaryCardHeader,
	roseCardHeader,
	blorinCardHeader,
	grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const cardTextStyle = {
	cardText: {
		float: "none",
		display: "inline-block",
		marginRight: "0",
		borderRadius: "3px",
		backgroundColor: grayColor[0],
		padding: "15px",
		marginTop: "-20px"
	},
	warningCardHeader,
	successCardHeader,
	dangerCardHeader,
	infoCardHeader,
	primaryCardHeader,
	blorinCardHeader,
	roseCardHeader
};

export default cardTextStyle;
