import React from "react";
import Data from "services/Data/Data";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

export function LoggingPage() {

	const handleDownloadLog = (e) => {
		e.preventDefault();

		Data.getLogFile().then((response) => {
			const type = response.headers['content-type']
			const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'Log.txt'
			link.click()
		});
	};

	return (
		<div>
			<GridContainer>
				<Button
					type="submit"
					color="blorin"
					size="lg"
					onClick={handleDownloadLog}
					block
				>
					Download Log
				</Button>
			</GridContainer>
		</div>
	);
}


