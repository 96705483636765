import React from "react";
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Charts from "components/Blorin/Charts/Charts";

export default function CardMonitoraggio(props) {

	return (
		<Card>
			<CardBody>
				<Charts
					type="building"
					idChart="ECPRodCons"
					device={props.device}
				/>
			</CardBody>
		</Card>
	);
}
