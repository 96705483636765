import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

//Auth lib
import AuthService from "services/Auth/auth.service";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
	const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
	React.useEffect(() => {
		let id = setTimeout(function () {
			setCardAnimation("");
		}, 700);
		// Specify how to clean up after this effect:
		return function cleanup() {
			window.clearTimeout(id);
		};
	});

	const [loading, setLoading] = React.useState(false);
	const [message, setMessage] = React.useState("");

	const handleLogin = (e) => {
		e.preventDefault();
		var username = e.target.username.value;
		var password = e.target.password.value;

		AuthService.login(username, password).then(
			() => {
				props.history.push("/");
				window.location.reload();
			},
			(error) => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setLoading(false);
				setMessage(resMessage);
			}
		);
	};

	const logo = require("assets/img/logo-blorin.png");

	const classes = useStyles();

	return (
		<div className={classes.container}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={6} md={4}>
					<form onSubmit={handleLogin}>
						<Card login className={classes[cardAnimaton]}>
							<CardHeader
								className={`${classes.cardHeader} ${classes.textCenter}`}
							>
								<img
									src={logo}
									alt="logo"
									className={classes.img}
								/>
							</CardHeader>
							<CardBody>
								{message && (
									<div className="form-group">
										<div
											className="alert alert-danger"
											role="alert"
										>
											{message}
										</div>
									</div>
								)}
								<CustomInput
									labelText="Username"
									id="username"
									name="username"
									required={true}
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Face
													className={
														classes.inputAdornmentIcon
													}
												/>
											</InputAdornment>
										),
									}}
								/>
								<CustomInput
									labelText="Password"
									id="password"
									name="password"
									required={true}
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Icon
													className={
														classes.inputAdornmentIcon
													}
												>
													lock_outline
												</Icon>
											</InputAdornment>
										),
										type: "password",
										autoComplete: "off",
									}}
								/>
							</CardBody>
							<CardFooter
								className={classes.justifyContentCenter}
							>
								<Button
									type="submit"
									color="blorin"
									size="lg"
									disabled={loading}
									block
								>
									Login
								</Button>
							</CardFooter>
						</Card>
					</form>
				</GridItem>
			</GridContainer>
		</div>
	);
}
