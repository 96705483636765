import React from "react";
import { useSelector } from 'react-redux';
import { getStoreVehicle } from "services/Redux/StoreSlice";


import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";

import Charts from "components/Blorin/Charts/Charts";
import NavPills from "components/NavPills/NavPills";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer";
import DateRange from "@material-ui/icons/DateRange";
import Utils from "services/Helper/Utils";

import { MapWithMarkers } from "components/Maps/Maps";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";



const useStyles = makeStyles(styles);


export function CardStatoCharts(props) {
	return (
		<Card>
			<CardBody>
				<NavPills
					color="blorin"
					tabs={[
						{
							tabButton: "Posizione dei veicoli ",
							tabContent: (
								<MapWithMarkers
									googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyANRQTSCUkZ4vPISlDS-NNNDkbrpN3Mbvk&callback=initMap"
									loadingElement={
										<div style={{ height: `100%` }} />
									}
									containerElement={
										<div
											style={{
												height: `380px`,
												borderRadius: "6px",
												overflow: "hidden",
											}}
										/>
									}
									mapElement={
										<div style={{ height: `100%` }} />
									}
								/>



							),
						},
						{
							tabButton: "Andamento della temperatura",
							tabContent: (
								<Charts
									device={props.device}
									type="vehicle"
									idChart="StatoTemperature"
								/>
							),
						},
						{
							tabButton: "Stato di carica della batteria ",
							tabContent: (
								<Charts
									device={props.device}
									type="vehicle"
									idChart="StatoSoc"
								/>
							),
						},
					]}
				/>
			</CardBody>
		</Card>
	);
}
export function CardStatoChartsOnlyMap() {

	return (
		<MapWithMarkers
			googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyANRQTSCUkZ4vPISlDS-NNNDkbrpN3Mbvk&callback=initMap"
			loadingElement={
				<div style={{ height: `100%` }} />
			}
			containerElement={
				<div
					style={{
						height: `380px`,
						borderRadius: "6px",
						overflow: "hidden",
					}}
				/>
			}
			mapElement={
				<div style={{ height: `100%` }} />
			}
		/>
	);
}

export function CardStatoIndicatori() {
	const classes = useStyles();

	var VehicleState = useSelector(getStoreVehicle);

	return (
		<Card>
			<CardHeader>
				<h3 className={classes.cardTitle}>Indicatori</h3>
			</CardHeader>
			<CardBody>
				<GridContainer>
					<GridItem xs={12} sm={6} md={6}>
						<h5>Stato veicolo:</h5>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<Button block className={classes.marginRight}>
							{" "}
							{VehicleState.Gear}
						</Button>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} sm={6} md={6}>
						<h5>Carica residua (SoC):</h5>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<Button block className={classes.marginRight}>
							{" "}
							{VehicleState.soc} %
						</Button>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} sm={6} md={6}>
						<h5>Stato di salute (SoH):</h5>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<Button block className={classes.marginRight}>
							{" "}
							{VehicleState.soh} %
						</Button>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} sm={6} md={6}>
						<h5>Caricatore inserito/non inserito:</h5>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<Button block className={classes.marginRight}>
							{" "}
							{VehicleState.Plug_State}
						</Button>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} sm={6} md={6}>
						<h5>In carica/non in carica:</h5>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<Button block className={classes.marginRight}>
							{" "}
							{VehicleState.Charge_Mode}
						</Button>
					</GridItem>
				</GridContainer>
			</CardBody>
			<CardFooter stats>
				<div className={classes.stats}>
					<DateRange />
					Last update: {Utils.timeConverter(VehicleState.timestampLC)}
				</div>
			</CardFooter>
		</Card>
	);
}
