import React from "react";
import { Route } from "react-router-dom";
export default class RouteServices {
	static getRoute = () => {
		return window.location.pathname !== "/admin/full-screen-maps";
	};

	static getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}
			var user = JSON.parse(localStorage.user)

			if (prop.layout === "/monit") {
				if (prop.roles.filter(element => user.roles.includes(element)).length > 0) {
					return (
						<Route
							path={prop.layout + prop.path}
							// path={prop.path}
							component={prop.component}
							key={key}
						/>
					);
				} else {
					return null;
				}
			} else {
				return null;
			}
		});
	};

	static getActiveRoute = (routes) => {
		let activeRoute = "Default Brand Text";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(
						routes[i].layout + routes[i].path
						// routes[i].path
					) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	static getPagesName = (routes) => {
		var url = window.location.href
		var path = url.substring(url.lastIndexOf("/"));
		var result = url.substring(url.lastIndexOf("/") + 1);
		routes.forEach(route => {
			if (route.views !== undefined) {
				route.views.forEach(view => {
					if (view.path === path) {
						result = route.name + "-" + view.name;
					}
				})
			}
		})
		return { url: url, name: result }
	};
}
