/** Definizione variabili globali charts */
// Highcharts.setOptions({

export const globalOptions = {
	time: {
		timezone: 'Europe/Rome',
		// timezone: 'America/New_York',
		useUTC: true,
	},
	lang: {
		shortMonths: [
			"Gen",
			"Feb",
			"Mar",
			"Apr",
			"Mag",
			"Giu",
			"Lug",
			"Ago",
			"Set",
			"Ott",
			"Nov",
			"Dic",
		],
		months: [
			"Gennaio",
			"Febbraio",
			"Marzo",
			"Aprile",
			"Maggio",
			"Giugno",
			"Luglio",
			"Agosto",
			"Settembre",
			"Ottobre",
			"Novembre",
			"Dicembre",
		],
		printChart: "Stampa",
		downloadJPEG: "Scarica come JPG",
		downloadPNG: "Scarica come PNG",
		downloadPDF: "Scarica come PDF",
		downloadSVG: "Scarica come SVG",
		contextButtonTitle: "Menu",
		rangeSelectorFrom: "Da:",
		rangeSelectorTo: "A:",
		weekdays: [
			"Domenica",
			"Lunedì",
			"Martedì",
			"Mercoledì",
			"Giovedì",
			"Venerdì",
			"Sabato",
		],
		decimalPoint: ",",
		thousandsSep: ".",
	},
};

export const dataGroupingLabels = {
	minute: ["%A %d/%m/%Y %H:%M", "%A %d/%m/%Y %H:%M", "-%H:%M"],
	hour: ["%A %d/%m/%Y %H:%M", "%A %d/%m/%Y %H:%M", "-%H:%M"],
	day: ["%A %d/%m/%Y", "%d/%m/%Y", "-%d/%m/%Y"],
	week: ["Settimana da %A %d/%m/%Y", "%d/%m/%Y", "-%d/%m/%Y"],
	month: ["%B %Y", "%B", "-%B %Y"],
	year: ["%Y", "%Y", "-%Y"],
};

export const dataGroupingSets = {
	1: {
		approximation: "average",
		forced: true,
		units: [
			["second", [1, 5, 15, 30]],
			["minute", [1, 5, 15, 30]],
			["hour", [1, 2, 3, 4, 6, 8, 12]],
			["day", [1, 2, 3, 4]],
			["week", [1]],
			["month", [1, 3, 6]],
			["year", null],
		],
		dateTimeLabelFormats: dataGroupingLabels,
	},
	2: {
		approximation: "sum",
		forced: true,
		units: [
			["day", [1]],
			["week", [1]],
			["month", [1, 3, 6]],
			["year", null],
		],

		dateTimeLabelFormats: dataGroupingLabels,
	},
	3: {
		//Usato solo per il datagrouping del navigator nei grafici stock a colonne
		approximation: "sum",
		forced: true,

		units: [["day", [5]]],
		//groupPixelWidth:10,
		dateTimeLabelFormats: dataGroupingLabels,
	},
};

export const rangeSelector = {
	1: [
		{
			type: "hour",
			count: 2,
			text: "2h",
		},
		{
			type: "day",
			count: 1,
			text: "1g",
		},
		{
			type: "day",
			count: 3,
			text: "3g",
		},
		{
			type: "week",
			count: 1,
			text: "1s",
		},
		{
			type: "month",
			count: 1,
			text: "1m",
		},
		{
			type: "month",
			count: 6,
			text: "6m",
		},
		{
			type: "year",
			count: 1,
			text: "1a",
		},
		{
			type: "all",
			text: "Tutto",
		},
	],
	2: [
		{
			type: "week",
			count: 1,
			text: "1s",
		},
		{
			type: "month",
			count: 1,
			text: "1m",
		},
		{
			type: "month",
			count: 6,
			text: "6m",
		},
		{
			type: "year",
			count: 1,
			text: "1a",
		},
		{
			type: "all",
			text: "Tutto",
		},
	],
};
