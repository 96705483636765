class Utils {
	static timeConverter = (timestamp) => {
		var a = new Date(timestamp);
		var months = [
			"Gen",
			"Feb",
			"Mar",
			"Apr",
			"Mag",
			"Giu",
			"Lug",
			"Ago",
			"Set",
			"Ott",
			"Nov",
			"Dic",
		];
		var year = a.getFullYear();
		var month = months[a.getMonth()];
		var date = a.getDate();
		var hour = a.getHours();
		var min = a.getMinutes();
		var sec = a.getSeconds();
		var time =
			date +
			" " +
			month +
			" " +
			year +
			" " +
			hour +
			":" +
			min +
			":" +
			sec;
		return time;
	};
}

export default Utils;
