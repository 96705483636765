import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import NavPills from "components/NavPills/NavPills";

import InfoNotif from "@material-ui/icons/InfoOutlined";
import DangerNotif from "@material-ui/icons/WarningOutlined";
import SuccessNotif from "@material-ui/icons/CheckCircleOutline";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";

export function NavPillsRU(props) {
	let tabs = [];
	props.reportsUsura.forEach((element, id) => {
		tabs.push({
			tabButton: element.label,
			tabContent: (
				<GridContainer key={id + "GcRU"}>
					<NotificationsRU
						key={id + "NoRU"}
						type={element.type}
						reports={element.reports}
					/>
				</GridContainer>
			),
		});
	});

	return (
		<Card>
			<CardBody>
				<NavPills color="blorin" tabs={tabs} />
			</CardBody>
		</Card>
	);
}

export function NotificationsRU(props) {
	let notifications = [];

	let icons = {
		info: InfoNotif,
		danger: DangerNotif,
		success: SuccessNotif,
	};

	props.reports.forEach((element, id) => {
		notifications.push(
			<>
				<GridItem key={id + "GiRU"} xs={12} sm={12} md={element.size ? element.size : 12}>
					<br />
					<SnackbarContent
						key={id + "ScRU"}
						message={element.message}
						icon={icons[element.status]}
						color={element.status}
					/>
				</GridItem>
			</>
		);
	});

	return <>{notifications}</>;
}
