import React, { useRef } from "react";
import Data from "services/Data/Data";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import TreeGridWidget from "./TreegridCheckboxes";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";

const useStyles = makeStyles(styles);

export function TablePolicyV2G() {
	const treegridRef = useRef();
	const classes = useStyles();
	const [alert, setAlert] = React.useState(null);

	const hideAlert = () => {
		setAlert(null);
	};

	const handleAddPolicyV2G = (e) => {
		e.preventDefault();
		let c_device = {
			id: devices[0].id,
			name: devices[0].label
		};

		var addToStore = {
			treegridState: treegridRef.current.state.checked,
			devices: c_device,
			connettore: 6,
			// soc: $("#soc").val(),
			id_colonnina: $("#id_colonnina").val(),
			timestamp: Date.now(),
			user: "USER",
			type: "v2g"
		};

		autoCloseAlert()
		Data.addPolicy(addToStore).then(() => {
		});
	};
	const autoCloseAlert = () => {
		setAlert(
			<SweetAlert
				style={{ display: "block", marginTop: "-100px" }}
				title="Policy inserita correttamente"
				onConfirm={() => hideAlert()}
				showConfirm={false}
			>
			</SweetAlert>
		);
		setTimeout(hideAlert, 2000);
	};

	var devices =
		[
			{
				id: "veicolo",
				value: "veicolo",
				label: "Intervallo disponibilità",
			},
		];

	var getData = () => {
		let data = devices

		let indexHour = 0;
		devices.forEach((element, index) => {
			let indexMinute = 1;
			console.log(element);
			data[index].children = [];
			for (let hour = 0; hour < 24; hour++) {
				let hourView = ('0' + hour).slice(-2)
				let minute_child = [];
				for (let minute = 0; minute < 60; minute += 15) {
					minute_child.push(
						{
							value: element.id + "-" + indexMinute,
							label: hourView + ":" + ('0' + minute).slice(-2)
						},
					)
					indexMinute++;
				}
				data[index].children.push(
					{
						value: indexHour,
						label: "Ore " + hourView,
						children: minute_child
					},
				);
				indexHour++;
			}
		});
		return data;
	}

	return (
		<>
			{alert}
			<GridContainer>
				<GridItem xs={8} sm={8} md={8} id="div_treegrid">
					<Card>
						<CardHeader>
							<CardText color="blorin">
								<h4 className={classes.cardTitle}>A che ora sei disponibile per svolgere una azione</h4>
							</CardText>
						</CardHeader>
						<CardBody>
							<TreeGridWidget ref={treegridRef} type="dr" data={getData()} />
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={4} sm={4} md={4}>
					<FormControl
						fullWidth
						className={classes.selectFormControl}
					>
						{/* <InputLabel
						htmlFor="simple-select"
						className={classes.selectLabel}
					>
						Veicolo
					</InputLabel> */}
						{/* <Select
						MenuProps={{
							className: classes.selectMenu
						}}
						classes={{
							select: classes.select
						}}
						value={simpleSelect}
						onChange={handleSimple}
						inputProps={{
							name: "simpleSelect",
							id: "simple-select"
						}}
					>
						<MenuItem
							classes={{
								root: classes.selectMenuItem
							}}
							value="EF434GF"
						>
							Nissan e-NV200 - EF434GF
						</MenuItem>
					</Select> */}
						<CustomInput
							labelText="ID Colonnina"
							id="id_colonnina"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: "string"
							}}
						/>
						{/* <CustomInput
						labelText="SoC minimo limite %"
						id="soc"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							type: "number"
						}}
					/> */}
					</FormControl>
					<Button
						type="submit"
						color="blorin"
						size="lg"
						onClick={handleAddPolicyV2G}
						block
					>
						Invia comunicazione
					</Button>
				</GridItem>
			</GridContainer>
		</>
	);
}
