import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FormEventiV2G, TableEventiV2G } from "./CardEvV2G-Sections"


function EventiComponents() {
	var user = JSON.parse(localStorage.user);
	var userRole = user.roles;
	if (userRole.includes("ROLE_DSO_V2G")) {
		return (
			<>
				<FormEventiV2G />
				<TableEventiV2G titleNuoviEventi="Nuove azioni" />
			</>
		);
	} else {
		return (
			<>
				<TableEventiV2G titleNuoviEventi="Nuove azioni per te" />
			</>
		);
	}
}


export default function CardEvV2G() {
	var user = JSON.parse(localStorage.user);
	var userRole = user.roles;
	var cardHeaderTitle = (userRole.includes("ROLE_DSO_V2G") ? "Messaggi al cliente" : "Messaggi dal DSO");
	return (
		<Card>
			<CardHeader color="blorin">
				<h3>{cardHeaderTitle}</h3>
			</CardHeader>
			<CardBody>
				<EventiComponents />
			</CardBody>
		</Card>
	);
}
