import React from "react";
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills";
import CardAuto from "./CardAuto";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";
import { CardStatoChartsOnlyMap } from "./CardST-Sections";


export default function CardStato() {
	var user = useSelector(getStoreUser);

	var tabs_devices = user.devices
		.filter(device => device.type === "vehicle")    /* filter available people */
		.map(device => {
			return {
				tabButton: device.label,
				tabContent: (
					<CardAuto
						device={device}
					/>
				)
			};
		});

	if (user.roles.includes("ROLE_DSO_V2G")) {
		return (
			<Card>
				<CardHeader color="blorin">
					<h3>
						I veicoli
					</h3>
				</CardHeader>
				<CardBody>
					<CardStatoChartsOnlyMap
						device={user.devices}
					/>
				</CardBody>
			</Card>
		);
	} else {
		return (
			<Card>
				<CardHeader color="blorin">
					<h3>
						I tuoi veicoli
					</h3>
				</CardHeader>
				<CardBody>
					<NavPills
						color="blorin"
						tabs={tabs_devices}
					/>
				</CardBody>
			</Card>
		);
	}
}
