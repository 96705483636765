import React from "react";
import ReactDOM from "react-dom";
import { store } from './services/Redux/Store';
import { Provider } from 'react-redux';
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import * as serviceWorker from "./serviceWorker";
import "assets/scss/styles.scss";
import { ProtectedRoute, PublicRoute } from "services/Routes/RoutesComponent";

const hist = createBrowserHistory();

ReactDOM.render(
	<React.Fragment>
		<Provider store={store}>
			<Router history={hist}>
				<Switch>
					<ProtectedRoute path="/monit" component={AdminLayout} />
					<PublicRoute path="/auth" component={AuthLayout} />
					{/* <Route path="/login" component={LoginPage} /> */}
					{/* <Route path="/demoserver" component={AdminLayout} /> */}
					{/* <Redirect from="/login" to="/auth/login" /> */}
					<Redirect from="/" to="/monit/v2g-stato" />
				</Switch>
			</Router>
		</Provider>
	</React.Fragment>,
	document.getElementById("root")
);

serviceWorker.unregister();
