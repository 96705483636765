import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import CardST from "components/Blorin/CardMonitoring/Stato/CardST";
import CardCB from "components/Blorin/CardMonitoring/CondizioniBatteria/CardCB";
import CardRU from "components/Blorin/CardMonitoring/ReportUsura/CardRU";
import CardRE from "components/Blorin/CardMonitoring/Rete/CardRE";
import CardEvV2G from "components/Blorin/CardEventi/CardEvV2G";
import { CardPoV2G, CardPoV2GDSO } from "components/Blorin/CardPolicy/CardPoV2G";
import Data from "services/Data/Data";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Info from "@material-ui/icons/Info";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";

export function Stato() {
	const dataVehicle = Data.getV2GVehicle();

	return (
		<div>
			<GridContainer>
				<CardST vehicle={dataVehicle} />
				<SnackbarContent
					message={
						"Info relative alla pagina"
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function CondizioniB() {
	const dataVehicle = Data.getV2GVehicle();
	return (
		<div>
			<GridContainer>
				<CardCB vehicle={dataVehicle} />
				<SnackbarContent
					message={
						"Quando la temperatura della batteria del tuo veicolo supera i 25°C, e lo stato di carica è sopra il 60% o sotto il 40%, parcheggia il tuo veicolo in un posto ombreggiato. "
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function ReportU() {
	const dataVehicle = Data.getV2GVehicle();
	const reportsUsura = Data.getReportsUsura();

	return (
		<div>
			<GridContainer>
				<CardRU vehicle={dataVehicle} reportsUsura={reportsUsura} />
				<SnackbarContent
					message={
						"Le condizioni che portano ad una usura precoce della batteria del tuo veicolo sono elencate di sopra. Il triangolo rosso indica una situazione critica in termini di possibile invecchiamento precoce della batteria. In questi casi puoi intervenire per normalizzare i parametri con un uso più attento del veicolo"
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function Rete() {
	const dataVehicle = Data.getV2GVehicle();
	// var Br = React.createClass({ render() { return (<>             {"\n"}{"\n"}             </>) } })
	return (
		<div>
			<GridContainer>
				<CardRE vehicle={dataVehicle} />
				<SnackbarContent
					message={"L’area rosa è l’energia immessa in rete dalla batteria del tuo veicolo, l’area verde è l’energia prelevata dalla rete per caricare la batteria del tuo veicolo. Il guadagno ottenuto dall’accumulo dei token potrà essere convertito in uno sconto in bolletta."}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function EventiV2G() {
	return (
		<div>
			<GridContainer>
				<CardEvV2G />
				<SnackbarContent
					message={
						"Puoi decidere quando e come intervenire per ottenere il massimo del guadagno"
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function PolicyV2G() {

	var user = useSelector(getStoreUser);
	if (user.roles.includes("ROLE_DSO_V2G")) {
		return (
			<div>
				<GridContainer>
					<CardPoV2GDSO />
					<SnackbarContent
						message={
							"Info relative alla pagina"
						}
						icon={Info}
						color="info"
					/>
				</GridContainer>
			</div>
		);
	} else {
		return (
			<div>
				<GridContainer>
					<CardPoV2G />
					<SnackbarContent
						message={
							"Info relative alla pagina"
						}
						icon={Info}
						color="info"
					/>
				</GridContainer>
			</div>
		);
	}
}