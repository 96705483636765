import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import CardED from "components/Blorin/CardMonitoring/Edifici/CardED";
import CardME from "components/Blorin/CardMonitoring/MonitoraggioEdifici/CardME";
import CardEvDR from "components/Blorin/CardEventi/CardEvDR";
import { CardPoDR, CardPoDRDSO } from "components/Blorin/CardPolicy/CardPoDR";
import CardMECB from "components/Blorin/CardMonitoring/MonitoraggioCabine/CardMECB";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Info from "@material-ui/icons/Info";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";

export function Edifici() {
	return (
		<div>
			<GridContainer>
				<CardED />
				<SnackbarContent
					message={
						"Info relative alla pagina"
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function Monitoraggio() {
	return (
		<div>
			<GridContainer>
				<CardME />
				<SnackbarContent
					message={
						"Target si riferisce alla richiesta di potenza del DSO che trovi in ‘Messaggi dal DSO’, baseline si riferisce al tuo consumo abituale, potenza invece è il consumo in tempo reale della tua abitazione."
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function EventiDR() {
	return (
		<div>
			<GridContainer>
				<CardEvDR />
				<SnackbarContent
					message={
						"Info relative alla pagina"
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}

export function PolicyDR() {

	var user = useSelector(getStoreUser);
	if (user.roles.includes("ROLE_DSO")) {
		return (
			<div>
				<GridContainer>
					<CardPoDRDSO />
					<SnackbarContent
						message={
							"Info relative alla pagina"
						}
						icon={Info}
						color="info"
					/>
				</GridContainer>
			</div>
		);
	} else {
		return (
			<div>
				<GridContainer>
					<CardPoDR />
					<SnackbarContent
						message={
							"Info relative alla pagina"
						}
						icon={Info}
						color="info"
					/>
				</GridContainer>
			</div>
		);

	}
}

export function MonitoraggioCB() {
	return (
		<div>
			<GridContainer>
				<CardMECB />
				<SnackbarContent
					message={
						"Info relative alla pagina"
					}
					icon={Info}
					color="info"
				/>
			</GridContainer>
		</div>
	);
}
