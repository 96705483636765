import React, { useRef, useState } from "react";
import Data from "services/Data/Data";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TreeGridWidget from "./TreegridCheckboxes"
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";

const useStyles = makeStyles(styles);

export function TablePolicyDR() {
	var user = useSelector(getStoreUser);
	const treegridRef = useRef();
	const classes = useStyles();
	const [alert, setAlert] = React.useState(null);
	const [simpleSelect, setSimpleSelect] = React.useState("");
	const [dispositivi, setDispositivi] = useState([]);
	const hideAlert = () => {
		setAlert(null);
	};

	const [seed, setSeed] = useState(1);
	const reloadTreegrid = () => {
		setSeed(Math.random());
	}

	const handleSimple = event => {
		setSimpleSelect(event.target.value);
		let dispositiviDevice = JSON.parse(event.target.value.data).dispositivi;
		setDispositivi(dispositiviDevice);
		reloadTreegrid()
	};



	var dropdown_devices = user.devices.filter(function (device) {
		if ((device.type === "building" || device.type === "buildingSnocu") && device.data != '') {
			return true;
		} else {
			return false;
		}
	}).map(device => {
		// selectElement.push(device.id)
		return (
			<MenuItem
				classes={{
					root: classes.selectMenuItem
				}}
				value={device}
				key={device.id}
			>
				{device.label}
			</MenuItem>)
	});


	const handleAddPolicy = (e) => {
		e.preventDefault();

		let c_device = [];

		dispositivi.forEach(device => {
			c_device.push(
				{
					id: device.id,
					name: device.label
				}
			);
		})

		var addToStore = {
			treegridState: treegridRef.current.state.checked,
			devices: c_device,
			timestamp: Date.now(),
			user: user.id,
			type: "dr",
		};
		autoCloseAlert()

		Data.addPolicy(addToStore).then(() => {
			console.log("OK");
		});
	};

	const autoCloseAlert = () => {
		setAlert(
			<SweetAlert
				title="Policy inserita correttamente"
				onConfirm={() => hideAlert()}
				showConfirm={false}
			>
			</SweetAlert>
		);
		setTimeout(hideAlert, 2000);
	};



	var getData = () => {
		let data = dispositivi

		let indexHour = 0;
		dispositivi.forEach((element, index) => {
			let indexMinute = 1;
			console.log(element);
			data[index].children = [];
			for (let hour = 0; hour < 24; hour++) {
				let hourView = ('0' + hour).slice(-2)
				let minute_child = [];
				for (let minute = 0; minute < 60; minute += 15) {
					minute_child.push(
						{
							value: element.id + "-" + indexMinute,
							label: hourView + ":" + ('0' + minute).slice(-2)
						},
					)
					indexMinute++;
				}
				data[index].children.push(
					{
						value: indexHour,
						label: "Ore " + hourView,
						children: minute_child
					},
				);
				indexHour++;
			}
		});
		return data;
	}

	return (
		<GridContainer>
			{alert}
			<GridItem xs={8} sm={8} md={8} id="div_treegrid">
				<Card>
					<CardHeader>
						<CardText color="blorin">
							<h4 className={classes.cardTitle}>Elettrodomestici</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<TreeGridWidget key={seed} ref={treegridRef} type="dr" data={getData()} />
					</CardBody>
				</Card>
			</GridItem>
			<GridItem xs={4} sm={4} md={4}>
				<FormControl
					fullWidth
					className={classes.selectFormControl}
				><InputLabel
					htmlFor="simple-select"
					className={classes.selectLabel}
				>
						Edifici
					</InputLabel>
					<Select
						MenuProps={{
							className: classes.selectMenu
						}}
						classes={{
							select: classes.select
						}}
						value={simpleSelect}
						onChange={handleSimple}
						inputProps={{
							name: "simpleSelect",
							id: "simple-select"
						}}
					>
						{dropdown_devices}
					</Select>
				</FormControl>
				<Button
					type="submit"
					color="blorin"
					size="lg"
					onClick={handleAddPolicy}
					block
				>
					Invia Policy
				</Button>
			</GridItem>
		</GridContainer>
	);
}
