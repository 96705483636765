import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills";
import CardMonitoraggioEC from "./CardMonitoraggioEC"
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";

export default function CardMEEC() {
	var user = useSelector(getStoreUser);

	var tabs_devices = user.devices
		.filter(device => device.type === "building")    /* filter available people */
		.map(device => {
			return {
				tabButton: device.label,
				tabContent: (
					<CardMonitoraggioEC
						device={device}
					/>
				)
			};
		});

	return (
		<Card>
			<CardHeader color="blorin">
				<h3>Monitoraggio Produzione/Consumi</h3>
			</CardHeader>
			<CardBody>
				<NavPills
					color="blorin"
					tabs={tabs_devices}
				/>
			</CardBody>
		</Card>
	);
}
