import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { getStoreVehicle } from "services/Redux/StoreSlice";
import {
	withGoogleMap,
	withScriptjs,
	GoogleMap,
	Marker,
} from "react-google-maps";


function Map() {

	var VehicleState = useSelector(getStoreVehicle);

	const [markerPosition,] = useState({ lat: 37.931737, lng: 12.326786, id: "abvs" });

	return (
		<GoogleMap
			defaultZoom={13}
			options={{ scrollwheel: false }}
			defaultCenter={{ lat: 37.931737, lng: 12.326786, }}
		>
			<Marker
				key={markerPosition.id}
				position={{
					lat: VehicleState.Lat,
					lng: VehicleState.Long
				}}
			/>
		</GoogleMap>
	);
}

export const MapWithMarkers = withScriptjs(withGoogleMap(Map));