import React from "react";
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import { Button, Collapse } from "react-bootstrap";
import Charts from "components/Blorin/Charts/Charts";

export default function CardMonitoraggio(props) {
	console.log("QUA-1");
	var idChart = "DrPotenza";

	if (props.device.id == 15) {
		idChart = "DrPotenzaPV";
	}

	return (
		<Card>
			<CardBody>
				<Charts
					type="building"
					idChart={idChart}
					device={props.device}
				/>
			</CardBody>
		</Card>
	);
}
