import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";
import CardCondizioniBatteria from "./CardCondizioniBatteria";
import NavPills from "components/NavPills/NavPills";

export default function CardCB() {
	var user = useSelector(getStoreUser);

	var tabs_devices = user.devices
		.filter(device => device.type === "vehicle")    /* filter available people */
		.map(device => {
			return {
				tabButton: device.label,
				tabContent: (
					<CardCondizioniBatteria
						device={device}
					/>
				)
			};
		});

	return (

		<Card>
			<CardHeader color="blorin">
				<h3>
					Le batterie dei tuoi veicoli
				</h3>
			</CardHeader>
			<CardBody>
				<NavPills
					color="blorin"
					tabs={tabs_devices}
				/>
			</CardBody>
		</Card>
	);
}
