import React from "react";
import Data from "services/Data/Data";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import ReactTable from "components/ReactTable/CustomReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Select from "@material-ui/core/Select";
import CardText from "components/Card/CardText.js";
import MenuItem from "@material-ui/core/MenuItem";

import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// Redux
import { useSelector } from 'react-redux';
import { getStoreEventsV2G } from "services/Redux/StoreSlice";
import { updateEvents } from 'services/Data/DataFunctions';
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { getStoreUser } from "services/Redux/StoreSlice";

const useStyles = makeStyles(styles);

export function FormEventiV2G() {
	const [alert, setAlert] = React.useState(null);
	const classes = useStyles();
	const [simpleSelect, setSimpleSelect] = React.useState("");
	var user = useSelector(getStoreUser);
	const handleSimple = (event) => {
		setSimpleSelect(event.target.value);
	};

	const handleAdd = (e) => {
		e.preventDefault();
		var addToStore = {
			userId: user.id,
			dateTimeStart: e.target.dateTimeStart.value,
			dateTimeEnd: e.target.dateTimeEnd.value,
			target: e.target.target.value,
			azione: simpleSelect,
			remunerazione: e.target.remunerazione.value,
			type: "v2g",
		};

		Data.addEventsV2G(addToStore).then(() => {
			// var jsonDataLive = res.data.response;
			updateEvents();
			autoCloseAlert();
		});
	};

	const autoCloseAlert = () => {
		setAlert(
			<SweetAlert
				style={{ display: "block", marginTop: "-100px" }}
				title="Azione inserito correttamente"
				onConfirm={() => hideAlert()}
				showConfirm={false}
			>
			</SweetAlert>
		);
		setTimeout(hideAlert, 2000);
	};

	const hideAlert = () => {
		setAlert(null);
	};

	return (
		<>{alert}
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardBody>
							<form onSubmit={handleAdd}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6}>
										<GridContainer>
											<GridItem xs={12} sm={9} md={3}>
												<FormLabel className={classes.labelHorizontal}>
													Data e ora inizio evento
												</FormLabel>
											</GridItem>
											<GridItem xs={12} sm={9} md={9}>
												<CustomInput
													id="dateTimeStart"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "datetime-local"
													}}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12} sm={6} md={6}>
										<GridContainer>
											<GridItem xs={12} sm={9} md={3}>
												<FormLabel className={classes.labelHorizontal}>
													Data e ora fine evento
												</FormLabel>
											</GridItem>
											<GridItem xs={12} sm={9} md={9}>
												<CustomInput
													id="dateTimeEnd"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "datetime-local"
													}}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6}>
										<GridContainer>
											<GridItem xs={12} sm={12} md={3}>
												<FormLabel className={classes.labelHorizontal}>
													Azione
												</FormLabel>
											</GridItem>
											<GridItem xs={12} sm={12} md={9}>
												<br></br>
												<Select
													MenuProps={{
														className: classes.selectMenu
													}}
													classes={{
														select: classes.select
													}}
													value={simpleSelect}
													onChange={handleSimple}
													inputProps={{
														name: "simpleSelect",
														id: "simple-select"
													}}
												>
													<MenuItem
														classes={{
															root: classes.selectMenuItem
														}}
														value="1"
													>
														Metti in carica il veicolo
													</MenuItem>
													<MenuItem
														classes={{
															root: classes.selectMenuItem
														}}
														value="2"
													>
														Metti in scarica il veicolo
													</MenuItem>
												</Select>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12} sm={6} md={6}>
										<GridContainer>
											<GridItem xs={12} sm={12} md={3}>
												<FormLabel className={classes.labelHorizontal}>
													Remunerazione €/evento
												</FormLabel>
											</GridItem>
											<GridItem xs={12} sm={12} md={9}>
												<CustomInput
													id="remunerazione"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "number",
														step: "0.001",
													}}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12} >
										<Button
											type="submit"
											color="blorin"
											size="lg"
											// s
											block
										>
											Inserisci
										</Button>
									</GridItem>
								</GridContainer>
							</form>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer >
		</>
	);
}


export function TableEventiV2G(props) {
	var user = useSelector(getStoreUser);
	const styles = {
		cardIconTitle: {
			...cardTitle,
			marginTop: "15px",
			marginBottom: "0px"
		}
	};

	const useStyles = makeStyles(styles);
	var EventsV2G = useSelector(getStoreEventsV2G);

	var history_events = []
	var events = []

	EventsV2G.forEach(evento => {
		var dateTimeStart = moment(evento.dateTimeStart, "DD/MM/YYYY hh:mm:ss"); // 1st argument - string, 2nd argument - format
		let azione = (evento.azione == 1 ? "Metti in carica il veicolo" : "Metti in scarica il veicolo");

		if (moment().unix() > dateTimeStart.unix()) {
			history_events.push(
				{
					id: evento.id,
					dateTimeStart: evento.dateTimeStart,
					dateTimeEnd: evento.dateTimeEnd,
					target: evento.target,
					azione: azione,
					remunerazione: evento.remunerazione.replace(".", ","),
					remunerazione_reale: evento.remunerazione_reale.replace(".", ","),
				}
			)

		} else {
			events.push(
				{
					id: evento.id,
					dateTimeStart: evento.dateTimeStart,
					dateTimeEnd: evento.dateTimeEnd,
					target: evento.target,
					azione: azione,
					remunerazione: evento.remunerazione.replace(".", ","),
					remunerazione_reale: evento.remunerazione_reale.replace(".", ","),
				}
			)
		}
	});

	var columnsNew;
	var columnsHistory;

	if (user.roles.includes("ROLE_DSO_V2G")) {
		columnsNew = [
			{
				Header: "Data inizio",
				accessor: "dateTimeStart"
			},
			{
				Header: "Data fine",
				accessor: "dateTimeEnd"
			},
			{
				Header: "Azione",
				accessor: "azione"
			},
			{
				Header: "Remunerazione",
				accessor: "remunerazione"
			}
		];

		columnsHistory = [
			{
				Header: "Data inizio",
				accessor: "dateTimeStart"
			},
			{
				Header: "Data fine",
				accessor: "dateTimeEnd"
			},
			{
				Header: "Azione",
				accessor: "azione"
			},
			{
				Header: "Remunerazione",
				accessor: "remunerazione"
			}
		];
	} else {
		columnsNew = [
			{
				Header: "Data inizio",
				accessor: "dateTimeStart"
			},
			{
				Header: "Data fine",
				accessor: "dateTimeEnd"
			},
			{
				Header: "Azione",
				accessor: "azione"
			},
			{
				Header: "Guadagno massimo che puoi ottenere",
				accessor: "remunerazione"
			},
		];
		columnsHistory = [
			{
				Header: "Data inizio",
				accessor: "dateTimeStart"
			},
			{
				Header: "Data fine",
				accessor: "dateTimeEnd"
			},
			{
				Header: "Azione",
				accessor: "azione"
			},
			{
				Header: "Guadagno reale che hai ottenuto",
				accessor: "remunerazione_reale"
			},
			{
				Header: "Guadagno massimo che potevi ottenere",
				accessor: "remunerazione"
			},
		];
	}



	const classes = useStyles();
	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader>
						<CardText color="blorin">
							<h4 className={classes.cardTitle}>{props.titleNuoviEventi}</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<ReactTable
							columns={columnsNew}
							data={events}
						/>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<CardText color="blorin">
							<h4 className={classes.cardTitle}>Storico</h4>
						</CardText>
					</CardHeader>
					<CardBody>
						<ReactTable
							columns={columnsHistory}
							data={history_events}
						/>
					</CardBody>
				</Card>

			</GridItem>
		</GridContainer>
	);
}
