import axios from "axios";
import https from "https";

const API_URL = "https://smet.exaltoenergia.it:8500/api/auth/";
const lifetimeLogin = 10 * 60 * 60 * 1000;

// const history = useHistory();
export default class AuthService {
	// login(username, password) {
	static login = (username, password) => {
		// console.log(username, password);
		return axios
			.post(API_URL + "signin", {
				username,
				password,
			}, {
				httpsAgent: new https.Agent({
					rejectUnauthorized: false
				})
			})
			.then((response) => {
				if (response.data.accessToken) {
					response.data.loginTs = Date.now();
					let user = JSON.stringify(response.data);
					localStorage.setItem("user", user);
				}
				return response.data;
			})
			.catch(() => {
				if (username === "demo1" && password === "demo") {
					const login_result = {
						id: 1,
						username: "mod",
						email: "mod@mod3.it",
						roles: ["ROLE_USER"],
						accessToken:
							"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjE2NTc3OTg2LCJleHAiOjE2MTY2NjQzODZ9.HNPa9P29B5-_LMDfgl6J9YGi4qOo1lG5l_jN9JCidfg",
					};
					let user = JSON.stringify(login_result);
					localStorage.setItem("user", user);
					return login_result;
				}
			});
	};

	static logout() {
		localStorage.removeItem("user");
	}

	// register(username, email, password) {
	// 	return axios.post(API_URL + "signup", {
	// 		username,
	// 		email,
	// 		password,
	// 	});
	// }

	static getCurrentUser() {
		let user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			if (user.loginTs + lifetimeLogin < Date.now()) {
				return false;
			} else {
				return JSON.parse(localStorage.getItem("user"));
			}
		}
	}
}
