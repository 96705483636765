import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthService from "services/Auth/auth.service";

export const PublicRoute = ({ ...props }) => {
	const isAllowed = AuthService.getCurrentUser();
	return isAllowed ? <Redirect to="/monit" /> : <Route {...props} />;
};

export const ProtectedRoute = ({ ...props }) => {
	const isAllowed = AuthService.getCurrentUser();
	return isAllowed ? <Route {...props} /> : <Redirect to="/auth/login" />;
};
