import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	user: {
		history: false
	},
	vehicle: {
		lat: 37.931737,
		lng: 12.326786,
		value: 0,
		status: 'idle',
		Gear: "",
		soc: "",
		soh: "",
		timestamp: 0,
		history: false
	},
	building: {
		history: false
	},
	events: {
		dr: [],
		v2g: [],
	}
};

export const StoreSlice = createSlice({
	name: 'store',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setStoreUser: (state, action) => {
			state.user = action.payload;
		},
		setStoreVehicle: (state, action) => {
			state.vehicle = action.payload;
		},
		setStoreBuilding: (state, action) => {
			state.building = action.payload;
		},
		setStoreEventDR: (state, action) => {
			state.events.dr = action.payload;
		},
		setStoreEventV2G: (state, action) => {
			state.events.v2g = action.payload;
		},
		addStoreEventDR: (state, action) => {
			state.events.dr.push(action.payload);
		},
		addStoreEventV2G: (state, action) => {
			state.events.v2g.push(action.payload);
		},
	},
});

export const { setStoreUser, setStoreVehicle, setStoreBuilding, setStoreEventDR, setStoreEventV2G, addStoreEventDR, addStoreEventV2G } = StoreSlice.actions;

export const getStoreAll = (state) => state.store;
export const getStoreUser = (state) => state.store.user;
export const getStoreVehicle = (state) => state.store.vehicle;
export const getStoreBuilding = (state) => state.store.building;
export const getStoreEventsDR = (state) => state.store.events.dr;
export const getStoreEventsV2G = (state) => state.store.events.v2g;


export default StoreSlice.reducer;
