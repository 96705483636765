import React from "react";
import { Switch, Route } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import login from "assets/img/sidebar-bg-blorin.jpg";

import RoutesMenu from "services/Routes/RoutesMenu";

const useStyles = makeStyles(styles);

export default function Pages() {
	// ref for the wrapper div
	const wrapper = React.createRef();
	// styles
	const classes = useStyles();
	React.useEffect(() => {
		document.body.style.overflow = "unset";
		// Specify how to clean up after this effect:
		return function cleanup() {};
	});
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.layout === "/auth") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	const getBgImage = () => {
		if (window.location.pathname.indexOf("/auth/login") !== -1) {
			return login;
		}
	};

	return (
		<div>
			<div className={classes.wrapper} ref={wrapper}>
				<div
					className={classes.fullPage}
					style={{ backgroundImage: "url(" + getBgImage() + ")" }}
				>
					<Switch>{getRoutes(RoutesMenu)}</Switch>
					<Footer white />
				</div>
			</div>
		</div>
	);
}
