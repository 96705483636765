import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { TablePolicyV2G } from "./CardPoV2G-Sections"
import ReactTable from "components/ReactTable/CustomReactTable.js";
import NavPills from "components/NavPills/NavPills";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";



function PolicyComponents() {
	return (
		<>
			<TablePolicyV2G />
		</>
	);
}

export function CardPoV2G() {

	var user = useSelector(getStoreUser);
	var tabs_devices = user.devices
		.filter(device => device.type === "vehicle")    /* filter available people */
		.map(device => {
			return {
				tabButton: device.label,
				tabContent: (
					<PolicyComponents />
				)
			};
		});

	return (
		<Card>
			<CardHeader color="blorin">
				<h3>Comunica con il DSO</h3>
			</CardHeader>
			<CardBody>
				<NavPills
					color="blorin"
					tabs={tabs_devices}
				/>

			</CardBody>
		</Card>
	);
}

export function CardPoV2GDSO() {

	// var user = useSelector(getStoreUser);
	// var tabs_devices = user.devices
	// 	.filter(device => device.type === "vehicle")    /* filter available people */
	// 	.map(device => {
	// 		return {
	// 			tabButton: device.label,
	// 			tabContent: (
	// 				<PolicyComponents />
	// 			)
	// 		};
	// 	});

	var policy = [
		{
			id: "asd",
			idColonnina: "A52F2E",
			data: "16/12/2022",
			intervalloDisponibilita: "11:00->15:00, 19:00->20:30",
		},
		{
			id: "asd",
			idColonnina: "A798BC",
			data: "16/12/2022",
			intervalloDisponibilita: "12:30->13:30, 18:30->19:30",
		}];

	return (
		<Card>
			<CardHeader color="blorin">
				<h3>Policy utenti</h3>
			</CardHeader>
			<CardBody>
				<ReactTable
					columns={[
						{
							Header: "ID colonnina",
							accessor: "idColonnina"
						},
						{
							Header: "Data",
							accessor: "data"
						},
						{
							Header: "Intervallo di disponibilità",
							accessor: "intervalloDisponibilita"
						},
					]}
					data={policy}
				/>
			</CardBody>
		</Card>
	);
}
