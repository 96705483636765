import React, { useState } from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardMonitoraggio from "./CardMonitoraggio"
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";

import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";


const useStyles = makeStyles(styles);

export default function CardME() {

	const classes = useStyles();
	var user = useSelector(getStoreUser);
	const [simpleSelect, setSimpleSelect] = useState(null);
	const [cardMonit, setCardMonit] = useState(null);
	const [deviceSelected, setDeviceSelected] = useState({});
	var selectElement = [];

	var tabs_devices = user.devices
		.filter(function (device) {
			if (device.type === "building" || device.type === "buildingSnocu") {
				return true;
			} else {
				return false;
			}
		})
		.map(device => {
			selectElement.push(device.id)
			return (
				<MenuItem
					classes={{
						root: classes.selectMenuItem
					}}
					value={device.id}
					key={device.id}
				>
					{device.label}
				</MenuItem>)
		});

	const handleSimple = event => {
		setCard(event.target.value)
	};

	const setCard = (value) => {
		let selected = user.devices.find(o => o.id === value);
		setDeviceSelected(selected);
		setSimpleSelect(value);
		setCardMonit(<CardMonitoraggio device={selected} />);
	}

	if (simpleSelect === null) {
		setCard(selectElement[0])
	}



	return (
		<>
			<FormControl
				fullWidth
				className={classes.selectFormControl}
			><InputLabel
				htmlFor="simple-select"
				className={classes.selectLabel}
			>
					Edifici
				</InputLabel>
				<Select
					MenuProps={{
						className: classes.selectMenu
					}}
					classes={{
						select: classes.select
					}}
					value={simpleSelect}
					onChange={handleSimple}
					inputProps={{
						name: "simpleSelect",
						id: "simple-select"
					}}
				>
					{tabs_devices}
				</Select>
			</FormControl>
			<Card>
				<CardHeader color="blorin">
					<h3>{deviceSelected.label}</h3>
				</CardHeader>
				<CardBody>
					{/* <NavPills
						color="blorin"
						tabs={tabs_devices2}
					/> */}
					{cardMonit}
				</CardBody>
			</Card>
		</>
	);
}
