import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import { useSelector } from 'react-redux';
import ChartStructures from "services/HighCharts/ChartStructures";
import Data from "services/Data/Data";
import { globalOptions } from "services/HighCharts/ChartStructureOptions";
// import moment from "moment";
import { getStoreEventsDR } from "services/Redux/StoreSlice";

import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

window.moment = Moment;
mTZ();

export default function Charts(props) {
	var chartComponent = useRef(null);
	const [options, setOptions] = useState();
	const [plotBandsState, setPlotBandsState] = useState([]);
	var chartOptions = ChartStructures(props.idChart, props);
	var EventsDR = useSelector(getStoreEventsDR);


	useEffect(() => {


		if (props.idChart === "DrPotenza" || props.idChart === "DrPotenzaPV") {

			if (EventsDR.length <= 0) {
				return;
			}
			let plotBands = []

			EventsDR.forEach(evento => {
				plotBands.push({
					from: Moment(evento.dateTimeStart, "DD/MM/YYYY hh:mm:ss").unix() * 1000,
					to: Moment(evento.dateTimeEnd, "DD/MM/YYYY hh:mm:ss").unix() * 1000,
					color: "#ffeb99",
					borderColor: "black",
					label: { text: "Evento DR" },
				})
			})
			setPlotBandsState(plotBands)
		}
		// eslint-disable-next-line
	}, [EventsDR, props]);

	useEffect(() => {
		setOptions({ xAxis: { plotBands: plotBandsState } });
	}, [plotBandsState]);

	/** Update History */
	// eslint-disable-next-line
	useEffect(() => {
		setOptions(chartOptions);
		Highcharts.setOptions(globalOptions);
		Data.getDataHistory({
			device_id: props.device.id,
			type: props.type,
			chart: props.idChart,
			series: chartOptions.series,
		}).then((res) => {


			if (res.data[0].data.length > 0 && res.data[0].data.length < 300) {
				setOptions({ series: res.data });
				setOptions({ rangeSelector: { selected: 6 } });
			} else if (res.data[0].data.length >= 300) {
				setOptions({ series: res.data });
				setOptions({ rangeSelector: { selected: 0 } });
			} else {
				setOptions({ series: [] });
			}

		}).catch(() => {
			setOptions({ series: [] });
		});
		// eslint-disable-next-line
	}, [props]);

	// /** Update Live from state */  
	// useEffect(() => {
	// 	serieIds.forEach((serie, i) => {
	// 		if (chartComponent.current.chart) {
	// 			if (storeData[props.type].timestamp && chartComponent.current.chart.series[0].points.length > 0) {
	// 				chartComponent.current.chart.series[i].addPoint([
	// 					storeData[[props.type]].timestamp,
	// 					adjustValue(serie, parseFloat(storeData[props.type][serie])),
	// 				]);
	// 			}
	// 		}			//Aggiungere funzione per aggiungere i punti del target nel grafico
	// 	});
	// }, [storeData[props.type]]);


	// /** Update Live from state */  
	// useEffect(() => {
	// 	serieIds.forEach((serie, i) => {
	// 		if (chartComponent.current.chart) {
	// 			if (storeData[props.type].timestamp && chartComponent.current.chart.series[0].points.length > 0) {
	// 				chartComponent.current.chart.series[i].addPoint([
	// 					storeData[[props.type]].timestamp,
	// 					adjustValue(serie, parseFloat(storeData[props.type][serie])),
	// 				]);
	// 			}
	// 		}			//Aggiungere funzione per aggiungere i punti del target nel grafico
	// 	});
	// }, [storeData[props.type]]);


	return (
		<>
			<HighchartsReact
				// allowChartUpdate={false}
				highcharts={Highcharts}
				// oneToOne={true}
				immutable={false}
				allowChartUpdate={true}
				ref={chartComponent}
				constructorType={"stockChart"}
				options={options}
			// updateArgs={[true, false, false]}
			/>
		</>
	);
}
