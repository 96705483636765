import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import CardMEEC from "components/Blorin/CardMonitoring/MonitoraggioEC/CardMEEC";

export function MonitoraggioEC() {
	return (
		<div>
			<GridContainer>
				<CardMEEC />
			</GridContainer>
		</div>
	);
}
