import React, { useEffect } from "react";
import Data from "services/Data/Data";
import Logging from "services/Logging/Logging";
import { useDispatch } from 'react-redux';
import {
	setStoreUser,
	// setStoreBuilding,
	setStoreVehicle,
} from '../services/Redux/StoreSlice';
import { updateEvents } from 'services/Data/DataFunctions';
import cx from "classnames";
import { Switch, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import RoutesMenu from "services/Routes/RoutesMenu";
import RouteServices from "services/Routes/Routes";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {

	const { ...rest } = props;
	// states and functions
	const updateInterval = 65 * 60 * 1000;
	const updateIntervalEvents = 65 * 60 * 1000;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [miniActive, setMiniActive] = React.useState(false);
	const image = require("assets/img/sidebar-bg-blorin.jpg");
	const color = "blorinYellow";
	const bgColor = "black";
	// const [hasImage, setHasImage] = React.useState(true);
	// const fixedClasses = React.useState("dropdown");
	const logo = require("assets/img/logo-blorin.png");
	// styles
	const classes = useStyles();

	const mainPanelClasses =
		classes.mainPanel +
		" " +
		cx({
			[classes.mainPanelSidebarMini]: miniActive,
			[classes.mainPanelWithPerfectScrollbar]:
				navigator.platform.indexOf("Win") > -1,
		});
	// ref for main panel div
	const mainPanel = React.createRef();
	// effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
	React.useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", resizeFunction);

		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf("Win") > -1) {
				ps.destroy();
			}
			window.removeEventListener("resize", resizeFunction);
		};
	});

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const sidebarMinimize = () => {
		setMiniActive(!miniActive);
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};


	const dispatch = useDispatch();
	var user = JSON.parse(localStorage.user);
	dispatch(setStoreUser(user));


	/* Funzione per il log */
	var page = RouteServices.getPagesName(RoutesMenu)

	var loggingData = {
		page: page,
		user: {
			id: user.id,
			username: user.username
		}
	}

	Logging.putLog(loggingData).then(() => {
		// console.log(res);
	});

	var homepage = "/monit/v2g-stato";
	if (user.roles.includes("ROLE_DR")) {
		homepage = "/monit/dr-edifici";
	} else if (user.roles.includes("ROLE_CE")) {
		homepage = "/monit/dr-minitoraggio-ce";
	} else if (user.roles.includes("ROLE_DSO")) {
		homepage = "/monit/dr-eventi";
	} else if (user.roles.includes("ROLE_DSO_V2G")) {
		homepage = "/monit/v2g-stato";
	} else if (user.roles.includes("ROLE_ADMIN")) {
		homepage = "/monit/log";
	}


	useEffect(() => {
		function updateVehicleLiveData() {
			// console.log(new Date, "REQUEST LIVE")
			Data.getDataLive({ type: "vehicle" }).then((res) => {
				var jsonDataLive = res.data.response;
				dispatch(setStoreVehicle(jsonDataLive));
				// console.log("After dispatch", jsonDataLive);
			});

			// Data.getDataLive({ type: "building" }).then((res) => {
			// 	var jsonDataLive = res.data.response;
			// 	dispatch(setStoreBuilding(jsonDataLive));
			// 	// console.log("After dispatch", jsonDataLive);
			// });
		}
		let have_vehicle = user.devices.find(device => device.type === 'vehicle');

		if (have_vehicle) {
			updateVehicleLiveData();
			const interval = setInterval(() => updateVehicleLiveData(), updateInterval);
			return () => {
				clearInterval(interval);
			}
		}
		// eslint-disable-next-line
	}, []);

	/** Get Events */
	useEffect(() => {
		// function updateEvents() {
		// 	// console.log(new Date, "REQUEST LIVE")
		// 	Data.getEvents({ type: "dr" }).then((res) => {
		// 		var jsonDataLive = res.data.response;
		// 		dispatch(setStoreEventDR(jsonDataLive));
		// 		console.log("After dispatch events", jsonDataLive);
		// 	});
		// }
		// if (userRole == "ROLE_DSO") {
		updateEvents();
		const interval = setInterval(() => updateEvents(), updateIntervalEvents);
		return () => {
			clearInterval(interval);
		};
		// }
		// eslint-disable-next-line
	}, []);




	return (
		<div className={classes.wrapper}>
			<Sidebar
				routes={RoutesMenu}
				logoText={"Blorin"}
				logo={logo}
				image={image}
				handleDrawerToggle={handleDrawerToggle}
				open={mobileOpen}
				color={color}
				bgColor={bgColor}
				miniActive={miniActive}
				{...rest}
			/>
			<div className={mainPanelClasses} ref={mainPanel}>
				<AdminNavbar
					sidebarMinimize={sidebarMinimize.bind(this)}
					miniActive={miniActive}
					brandText={RouteServices.getActiveRoute(RoutesMenu)}
					handleDrawerToggle={handleDrawerToggle}
					{...rest}
				/>


				<div className={classes.content}>
					<div className={classes.container}>
						<Switch>
							{RouteServices.getRoutes(RoutesMenu)}

							<Redirect from="/" to={homepage} />
						</Switch>
					</div>
				</div>




				{RouteServices.getRoute() ? <Footer fluid /> : null}
			</div>
		</div >
	);
}
