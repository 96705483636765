import axios from "axios";
import authHeader from "services/Auth/auth-header";

const API_URL = "https://smet.exaltoenergia.it:8500/api/";

export default class Logging {
	/** Funzione generale per Live */

	static putLog = (props) => {
		var json = props;
		return axios.post(API_URL + "logging/putlog", json, {
			headers: authHeader()
		});
	};
}
