/** Import Views */
import { Edifici, Monitoraggio, EventiDR, PolicyDR, MonitoraggioCB } from "views/DR/DemandResponse";
import { MonitoraggioEC } from "views/EC/EnergyCommunity";
import { LoggingPage } from "views/Admin/LoggingPage";
import { Stato, Rete, CondizioniB, ReportU, EventiV2G, PolicyV2G } from "views/V2G/V2G";
import LoginPage from "views/Pages/LoginPage";

/** Import Icons */
import Batteria from "@material-ui/icons/BatteryFull";
import BarChart from "@material-ui/icons/BarChart";
import ShowChart from "@material-ui/icons/ShowChart";
import EvStation from "@material-ui/icons/EvStation";
import House from "@material-ui/icons/House";
import Dashboard from "@material-ui/icons/Dashboard";
import SyncAlt from "@material-ui/icons/SyncAlt";
import Info from "@material-ui/icons/Info";
import Autorenew from "@material-ui/icons/Autorenew";
import Assignment from "@material-ui/icons/Assignment";
import { HomeWork, Settings, Description } from "@material-ui/icons";


var dashRoutes = [
	{
		show: true,
		collapse: true,
		name: "V2G",
		icon: EvStation,
		state: "v2gCollapse",
		roles: ["ROLE_V2G", "ROLE_DSO_V2G"],
		views: [
			{
				path: "/v2g-stato",
				name: "Stato",
				mini: "S",
				icon: Dashboard,
				component: Stato,
				layout: "/monit",
				roles: ["ROLE_V2G", "ROLE_DSO_V2G"]
			},
			{
				path: "/v2g-rete",
				name: "Rete",
				mini: "R",
				icon: SyncAlt,
				component: Rete,
				layout: "/monit",
				roles: ["ROLE_V2G", "ROLE_DSO_V2G"]
			},
			{
				path: "/v2g-ca",
				name: "Condizioni batteria",
				mini: "CA",
				icon: Batteria,
				component: CondizioniB,
				layout: "/monit",
				roles: ["ROLE_V2G"]
			},
			{
				path: "/v2g-cu",
				name: "Report usura",
				mini: "CU",
				icon: Info,
				component: ReportU,
				layout: "/monit",
				roles: ["ROLE_V2G"]
			},
			{
				path: "/v2g-eventi",
				name: getLabel("v2g-eventi"),
				mini: "PP",
				icon: Assignment,
				component: EventiV2G,
				layout: "/monit",
				roles: ["ROLE_V2G", "ROLE_DSO_V2G"]
			},
			{
				path: "/v2g-policy",
				name: getLabel("v2g-policy"),
				mini: "PO",
				icon: Assignment,
				component: PolicyV2G,
				layout: "/monit",
				roles: ["ROLE_V2G", "ROLE_DSO_V2G"]

			},
		],
	},
	{
		collapse: true,
		name: "Demand Response",
		icon: Autorenew,
		state: "drCollapse",
		roles: ["ROLE_DR", "ROLE_DSO"],
		views: [
			{
				path: "/dr-edifici",
				name: getLabel("dr-edifici"),
				mini: "PP",
				icon: House,
				component: Edifici,
				layout: "/monit",
				roles: ["ROLE_DR", "ROLE_DSO"]
			},
			{
				path: "/dr-eventi",
				name: getLabel("dr-eventi"),
				mini: "PP",
				icon: Assignment,
				component: EventiDR,
				layout: "/monit",
				roles: ["ROLE_DR", "ROLE_DSO"]
			},
			{
				path: "/dr-monitoraggio",
				name: "Monitoraggio",
				mini: "PP",
				icon: BarChart,
				component: Monitoraggio,
				layout: "/monit",
				roles: ["ROLE_DR"]
			},
			{
				path: "/dr-cb-monitoraggio",
				name: "Monitoraggio Cabine",
				mini: "MCCB",
				icon: BarChart,
				component: MonitoraggioCB,
				layout: "/monit",
				roles: ["ROLE_DSO"]
			},
			{
				path: "/dr-policy",
				name: getLabel("dr-policy"),
				mini: "PO",
				icon: Assignment,
				component: PolicyDR,
				layout: "/monit",
				roles: ["ROLE_DR", "ROLE_DSO"]
			},
		],
	},
	{
		collapse: true,
		name: "Energy Community",
		icon: HomeWork,
		state: "ecCollapse",
		roles: ["ROLE_CE"],
		views: [
			{
				path: "/ec-minitoraggio",
				name: "Monitoraggio",
				mini: "MEEC",
				icon: ShowChart,
				component: MonitoraggioEC,
				layout: "/monit",
				roles: ["ROLE_CE"]
			},
		],
	},
	{
		collapse: true,
		name: "Administrator",
		icon: Settings,
		state: "ecCollapse",
		roles: ["ROLE_ADMIN"],
		views: [
			{
				path: "/log",
				name: "Log",
				mini: "LOG",
				icon: Description,
				component: LoggingPage,
				layout: "/monit",
				roles: ["ROLE_ADMIN"]
			},
		],
	},
	// {
	// 	path: "/test",
	// 	name: "Test",
	// 	mini: "TT",
	// 	icon: House,
	// 	component: TestView,
	// 	layout: "/monit",
	// 	roles: ["ROLE_USER"]
	// },
	{
		redirect: true,
		path: "/login",
		name: "Login Page",
		component: LoginPage,
		layout: "/auth",
	},
];
export default dashRoutes;

function getLabel(page) {
	if (localStorage.user == undefined) {
		return "-"
	}
	var user = JSON.parse(localStorage.user);
	var label;
	switch (page) {
		case "v2g-eventi":
		case "dr-eventi":
			label = (user.roles.includes("ROLE_DSO") || user.roles.includes("ROLE_DSO_V2G") ? "Messaggi al Cliente" : "Messaggi dal DSO");
			break;
		case "v2g-policy":
		case "dr-policy":
			label = (user.roles.includes("ROLE_DSO") || user.roles.includes("ROLE_DSO_V2G") ? "Policy utenti" : "Comunica col DSO");
			break;
		case "dr-edifici":
			label = (user.roles.includes("ROLE_DSO") || user.roles.includes("ROLE_DSO_V2G") ? "Utenze" : "Le tue abitazioni");
			break;
		default:
			break;
	}
	return label;
}