import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import './CustomTreegrid.css';

import Icon from "@material-ui/core/Icon";

class TreeGridWidget extends React.Component {

	constructor(props) {
		super(props);
		this.data = props.data;
	}

	state = {
		checked: [],
		expanded: [],
	};

	render() {
		return (
			<>
				<CheckboxTree
					id="treegrid"
					direction="center"
					nodes={this.data}
					checked={this.state.checked}
					expanded={this.state.expanded}
					showNodeIcon={false}
					nativeCheckboxes={true}
					onCheck={checked => this.setState({ checked })}
					onExpand={expanded => this.setState({ expanded })}
					icons={{
						expandClose: <Icon>expand_more</Icon>,
						expandOpen: <Icon>expand_less</Icon>,
					}}
				/>
			</>
		);
	}
}


export default TreeGridWidget;