import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "components/ReactTable/CustomReactTable.js";
import { TablePolicyDR } from "./CardPoDR-Sections"


function PolicyComponents() {
	return (
		<>
			<TablePolicyDR />
		</>
	);
}

export function CardPoDR() {
	return (
		<Card>
			<CardHeader color="blorin">
				<h3>Scegli i dispositivi che vuoi attivare</h3>
			</CardHeader>
			<CardBody>
				<PolicyComponents />
			</CardBody>
		</Card>
	);
}

export function CardPoDRDSO() {

	var policy = [
		{
			id: "asd",
			user: "Di Malta - 4587",
			dispositivi_disponibili: "Accumulo, Lavatrice",
			intervalloDisponibilita: "11:00->15:00, 19:00->20:30",
		},
		{
			id: "asd",
			user: "Interflora - 1523",
			dispositivi_disponibili: "Accumulo, Scaldabagno",
			intervalloDisponibilita: "12:30->13:30, 18:30->19:30",
		}];

	return (
		<Card>
			<CardHeader color="blorin">
				<h3>Policy utenti</h3>
			</CardHeader>
			<CardBody>
				<ReactTable
					columns={[
						{
							Header: "Nome utenza",
							accessor: "user"
						},
						{
							Header: "Elettrodomestici disponibili",
							accessor: "dispositivi_disponibili"
						},
						{
							Header: "Intervallo di disponibilità",
							accessor: "intervalloDisponibilita"
						},
					]}
					data={policy}
				/>
			</CardBody>
		</Card>
	);
}
