import React from "react";
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import { Button, Collapse } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridContainer from "components/Grid/GridContainer";

import auto from "assets/img/nissan_env200.png";
import { CardStatoCharts, CardStatoIndicatori } from "./CardST-Sections";

const useStyles = makeStyles(styles);

export default function CardAuto(props) {
	const classes = useStyles();
	var vehicle = JSON.parse(props.device.data);

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={12} md={5}>
					<Card product className={classes.cardHover}>
						<CardHeader image></CardHeader>
						<CardBody>

							<div
								className={
									classes.cardProductTitleImageContainer
								}
							>
								<img
									src={auto}
									className={
										classes.cardProductTitleImage
									}
									alt="..."
								/>
							</div>
							<h4
								className={classes.cardProductTitle}
							>
								{vehicle.marca}{" "}
								{vehicle.modello} -{" "}
								{vehicle.targa}
							</h4>
							<p
								className={
									classes.cardProductDesciprion
								}
							>
								{vehicle.caratteristiche.map(
									(element, i) => {
										return (
											<span key={i}>
												{element}
												<br />
											</span>
										);
									}
								)}
							</p>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={12} md={7}>
					<CardStatoIndicatori />
				</GridItem>
			</GridContainer>

			<CardStatoCharts
				device={props.device}
			/>
		</>
	);
}
