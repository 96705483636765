import React from "react";
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import { Button, Collapse } from "react-bootstrap";
import Charts from "components/Blorin/Charts/Charts";

export default function CardMonitoraggioCabine(props) {

	return (
		<Card>
			<CardBody>
				<Charts
					type="cabin"
					idChart="DrPotenzaOnly"
					device={props.device}
				/>
			</CardBody>
		</Card>
	);
}
