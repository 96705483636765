import React from "react";

import Table from "components/Table/Table.js";

import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import { ArrowUpward, ArrowDownward, PowerSettingsNew, Euro } from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);



export function CardEdifici() {
	return (
		<Card>
			<CardBody>
				<NavPills
					color="blorin"
					tabs={[
						// {
						// 	tabButton: "Eventi",
						// 	tabContent: (
						// 		<TableEventiRE />
						// 	),
						// },
						{
							tabButton: "Stato",
							tabContent: (
								<TableStatoRE />
							),
						}
						// {
						// 	tabButton: "Remunerazione",
						// 	tabContent: (
						// 		<TableRemunerazioneRE />
						// 	),
						// },
					]}
				/>
			</CardBody>
		</Card>
	);
}



export function TableEventiRE() {
	const classes = useStyles();

	const upIcon = (
		<Button
			round
			color="success"
			className={classes.actionButton + " " + classes.actionButtonRound + " " + classes.disableButtonClick}
		>
			<ArrowUpward />
		</Button>
	)

	const downIcon = (
		<Button
			round
			color="danger"
			className={classes.actionButton + " " + classes.actionButtonRound + " " + classes.disableButtonClick}
		>
			<ArrowDownward />
		</Button>
	)

	return (
		<>
			<Table
				tableHead={[
					"",
					"Ora",
					"Azione",
					"Durata"
				]}
				tableData={[
					[
						downIcon,
						"12:00",
						"Spegnimento scaldabagno ",
						"60 min.",
					],
					[
						upIcon,
						"15:00",
						"Accensione scaldabagno ",
						"120 min.",
					]
				]}
			/>
		</>
	);
}

export function TableStatoRE() {
	const classes = useStyles();

	const onIcon = (
		<Button
			round
			color="success"
			className={classes.actionButton + " " + classes.actionButtonRound + " " + classes.disableButtonClick}
		>
			<PowerSettingsNew />
		</Button>
	)

	const offIcon = (
		<Button
			round
			color="danger"
			className={classes.actionButton + " " + classes.actionButtonRound + " " + classes.disableButtonClick}
		>
			<PowerSettingsNew />
		</Button>
	)

	return (
		<>
			<Table
				tableHead={[
					"Elettrodomestico/Apparato",
					"Stato",
					"Valori",
				]}
				tableData={[
					[
						"Scaldabagno",
						onIcon,
						"Temperatura Acqua: 50 °C",
					],
					[
						"Lavatrice",
						offIcon,
						"",
					],
					[
						"Aria Condizionata",
						onIcon,
						"Temperatura: 19 °, Fan Speed: 3",
					]
				]}
			/>
		</>
	);
}

export function TableRemunerazioneRE() {
	const classes = useStyles();

	const euroIcon = (
		<Button
			active={true}
			round
			color="success"
			className={classes.actionButton + " " + classes.actionButtonRound + " " + classes.disableButtonClick}
		>
			<Euro />
		</Button>
	)

	return (
		<>
			<NavPills
				color="blorin"
				alignCenter
				tabs={[
					{
						tabButton: "Giornaliera",
						tabContent: (
							<Table
								tableHead={[
									"",
									"Data",
									"Importo",
								]}
								tableData={[
									[
										euroIcon,
										"01/10/2021",
										"10 €",
									],
									[
										euroIcon,
										"02/10/2021",
										"13 €",
									],
									[
										"",
										"03/10/2021",
										"-",
									],
									[
										euroIcon,
										"04/10/2021",
										"5 €",
									],
								]}
							/>
						),
					},
					{
						tabButton: "Mensile",
						tabContent: (

							<Table
								tableHead={[
									"",
									"Mese",
									"Importo",
								]}
								tableData={[
									[
										euroIcon,
										"Luglio",
										"130 €",
									],
									[
										euroIcon,
										"Agosto",
										"90 €",
									],
									[
										euroIcon,
										"Settembre",
										"150 €",
									],
									[
										euroIcon,
										"Ottobre",
										"28 €",
									],
								]}
							/>
						),
					}
				]}
			/>

		</>
	);
}