import React from "react";
// import AccessTime from "@material-ui/icons/AccessTime";
// core components
import Charts from "components/Blorin/Charts/Charts";

export default function CardCondizioniBatteria(props) {
	return (
		<>
			<Charts
				type="vehicle"
				idChart="ReteCB"
				device={props.device}
			/>
			<hr className="col-xs-12"></hr>
			{/* <GridContainer>
				<GridItem xs={12} sm={12} md={4}>
					<Card>
						<CardHeader color="success" stats icon>
							<CardIcon color="success">
								<Euro />
							</CardIcon>
							<p className={classes.cardCategory}>
								Risparmi
							</p>
							<h3 className={classes.cardTitle}>
								€34,24
							</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<DateRange />
								Dal 01/01/2021
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={12} md={4}>
					<Card>
						<CardHeader color="success" stats icon>
							<CardIcon color="success">
								<Icon>paid</Icon>
							</CardIcon>
							<p className={classes.cardCategory}>
								Token
							</p>
							<h3 className={classes.cardTitle}>
								75
							</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<DateRange />
								Dal 01/01/2021
							</div>
						</CardFooter>
					</Card>
				</GridItem>
			</GridContainer> */}
		</>
	);
}
