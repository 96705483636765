import React from "react";

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import NavPills from "components/NavPills/NavPills";
import CardRete, { CardReteDSO } from "./CardRete";

import GridContainer from "components/Grid/GridContainer";
import { useSelector } from 'react-redux';
import { getStoreUser } from "services/Redux/StoreSlice";

export default function CardRE() {
	var user = useSelector(getStoreUser);
	var cardHeaderTitle, chartTitle, cardBody, lastDevice;



	var tabs_devices = user.devices
		.filter(device => device.type === "vehicle")    /* filter available people */
		.map(device => {
			lastDevice = device;
			return {
				tabButton: device.label,
				tabContent: (
					<CardRete
						type="building"
						idChart="DrPotenza"
						device={device}
						title={chartTitle}
					/>
				)
			};
		});

	if (user.roles.includes("ROLE_DSO_V2G")) {
		cardHeaderTitle = "I veicoli e la rete";
		chartTitle = "Prelievo/Immissione di tutti i Veicoli V2G"
		cardBody = (<CardReteDSO
			type="building"
			idChart="DrPotenza"
			device={lastDevice}
			title={chartTitle}
		/>)
	} else {
		cardHeaderTitle = "I tuoi veicoli e la rete";
		chartTitle = "Prelievo energia dalla rete/Immissione energia in rete"
		cardBody = (<NavPills
			color="blorin"
			tabs={tabs_devices}
		/>)
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="blorin">
						<h3>
							{cardHeaderTitle}
						</h3>
					</CardHeader>
					<CardBody>
						{cardBody}
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
