import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FormEventiDR, TableEventiDR } from "./CardEvDR-Sections"


function EventiComponents() {
	var user = JSON.parse(localStorage.user);
	var userRole = user.roles;

	if (userRole.includes("ROLE_DSO")) {
		return (
			<>
				<FormEventiDR />
				<TableEventiDR titleNuoviEventi="Nuove azioni" />
			</>
		);
	} else {
		return (
			<>
				<TableEventiDR titleNuoviEventi="Nuove azioni per te" />
			</>
		);
	}
}

export default function CardEvDR() {
	var user = JSON.parse(localStorage.user);
	var userRole = user.roles;
	var cardHeaderTitle = (userRole.includes("ROLE_DSO") ? "Messaggi al cliente" : "Messaggi dal DSO");
	return (
		<Card>
			<CardHeader color="blorin">
				<h3>{cardHeaderTitle}</h3>
			</CardHeader>
			<CardBody>
				<EventiComponents />
			</CardBody>
		</Card>
	);
}
